import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DecisionService} from "../../services/decision.service";
import {ToastService} from "../../services/general/toast.service";

@Component({
  selector: 'app-modal-add-text',
  templateUrl: './modal-add-text.component.html',
  styleUrls: ['./modal-add-text.component.scss']
})
export class ModalAddTextComponent {
  addTextForm: FormGroup;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAddTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      value?: string,
      submitFunction?: any,
      fieldName?: string,
      args?: any,
      successString?: string
    },
    private toast: ToastService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private decisionService: DecisionService
  ) {
    if (!data?.title) {
      this.data.title = 'generic.buttons.modify';
    }
    this.addTextForm = this.fb.group({
      value: [data.value || '', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.addTextForm.valid) {
      const value = this.addTextForm.get('value').value;
      if (this.data?.submitFunction && this.data?.fieldName) {
        this.loading = true;
        const model = {
          [this.data.fieldName]: value
        };
        this.decisionService.updateDecisionTable(this.data.args, model, (response) => {
          this.toast.success(
            this.translateService.instant('generic.success'),
            this.translateService.instant('generic.updated-successfully')
          );
          this.dialogRef.close(value);
          this.loading = false;
        }, (error) => {
          this.toast.error(
            this.translateService.instant('generic.error'),
            this.translateService.instant('generic.updated-failed')
          );
          this.loading = false;
        });
      } else {
        this.dialogRef.close(value);
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
