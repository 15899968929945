import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {DecisionService} from '../../services/decision.service';
import {Router, ActivatedRoute} from '@angular/router';
import {JoinRequestService} from '../../services/join-requests.service';
import {ToastService} from '../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-decision-join-request',
  templateUrl: './decision-join-request.component.html',
  styleUrls: ['./decision-join-request.component.scss']
})
export class DecisionJoinRequestComponent implements OnInit {
  @ViewChild('stepper') private myStepper!: MatStepper;

  invitationPage: any = null;
  loading = true;
  form: FormGroup;
  thankYouPageEnabled = false;
  decisionId: string | null = null;
  isPhoneNumberMandatory = false;
  submitted = false;

  constructor(
    private decisionService: DecisionService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private joinRequestService: JoinRequestService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      number_phone: ['', [
        Validators.pattern(/^\+?\d{7,15}$/)
      ]],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.decisionId = params.get('id_decision');
      if (this.decisionId) {
        this.getInvitationPage();
      }
    });
  }

  getInvitationPage(): void {
    this.loading = true;
    this.decisionService.getInvitationPageByDecisionTable(this.decisionId, (result) => {
      this.invitationPage = result;
      this.thankYouPageEnabled = !!result.customs?.enableThankYouPage;
      this.isPhoneNumberMandatory = !!result.customs?.numberPhoneIsMandatory;

      const phoneNumberControl = this.form.get('number_phone');
      if (this.isPhoneNumberMandatory) {
        phoneNumberControl?.setValidators([Validators.required]);
      } else {
        phoneNumberControl?.clearValidators();
      }
      phoneNumberControl?.updateValueAndValidity();

      this.loading = false;
    }, () => {
      this.loading = false;
      this.router.navigate(['/']);
    });
  }

  onSubmit(): void {
    const phoneNumberControl = this.form.get('number_phone');

    // Definisci il pattern per il numero di telefono
    const phoneNumberPattern = /^\+?\d{7,15}$/;

    if (phoneNumberControl) {
      const phoneNumber = phoneNumberControl.value;
      if (phoneNumber && !phoneNumberPattern.test(phoneNumber)) {
        phoneNumberControl.setErrors({ pattern: true });
      } else {
        phoneNumberControl.setErrors(null);
      }
    }

    if (this.form.valid) {
      const data = {
        ...this.form.value,
        decision_table_invitation_page_id: this.invitationPage.id,
      };

      this.loading = true;
      this.joinRequestService.createJoinRequest(data, () => {
        this.loading = false;
        this.submitted = true;
        this.toastService.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('components.decision-table.show-decision-table.invitation-page-submitted-successfully')
        );

        this.form.disable();
        if (this.thankYouPageEnabled) {
          setTimeout(() => {
            this.myStepper.next();
            this.myStepper.next();
          }, 100);
        } else {
          this.router.navigate(['/']);
        }
      }, () => {
        this.loading = false;
      });
    } else {
      this.form.markAllAsTouched();
    }
  }


  done(): void {
    this.router.navigate(['/']);
  }
}
