<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<ng-container *ngIf="!loading">
  <form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

    <!-- Blocco 1 - Obiettivi -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">1</span>
                {{'generic.objectives' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12 px-2" *ngIf="model.primary_target">
                <div class="box px-0 box-two-row">
                  <mat-label
                    class="box-title px-3">{{'components.decision-table.new-decision-table.compile-proposal.primary' | translate}}</mat-label>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="proposal_target_primary_title" [ngModel]="model.primary_target.title"
                           placeholder="{{'components.decision-table.new-decision-table.compile-proposal.title' | translate}}"
                           readonly>
                  </mat-form-field>
                  <mat-form-field class="datepicker-primary col-3" appearance="fill" (click)="dp_primary.open()">
                    <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="dp_primary" name="proposal_target_primary_date"
                           class="color-default ng-trim-ignore" [(ngModel)]="model.primary_target.date" required
                           disabled>
                    <mat-datepicker-toggle matSuffix [for]="dp_primary">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp_primary disabled="false"></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-3" appearance="fill">
                    <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.measure-unit' | translate}}</mat-label>
                    <mat-select name="proposal_target_primary_criteria_unit"
                                [(ngModel)]="model.primary_target.measure_unit" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()"
                                  [value]="unit.key">{{unit.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="proposal_target_primary_evaluation_criteria"
                           placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}"
                           [ngModel]="getCriteriaService().getCriteriaValueByKey(model.primary_target.evaluation_criteria)"
                           readonly>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="proposal_target_primary_expected_value"
                           placeholder="{{'components.decision-table.new-decision-table.compile-proposal.desidered-value' | translate}}"
                           [(ngModel)]="model.primary_target.expected_value" autocomplete="off">
                  </mat-form-field>
                </div>
              </div>

              <div class="col-6 px-2" *ngFor="let targetSecondary of model.secondary_targets; let i = index">
                <ng-container>
                  <div class="box px-0 box-two-row">
                    <mat-label
                      class="box-title px-3">{{'components.decision-table.new-decision-table.compile-proposal.secondary' | translate}}</mat-label>
                    <mat-form-field class="input-primary col-7">
                      <input matInput name="proposal_target_secondary_title{{i}}" [ngModel]="targetSecondary.title"
                             placeholder="{{'components.decision-table.new-decision-table.compile-proposal.title' | translate}}"
                             readonly>
                    </mat-form-field>
                    <mat-form-field class="datepicker-primary col-5" appearance="fill" (click)="dp_secondary.open()">
                      <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                      <input matInput [matDatepicker]="dp_secondary" name="proposal_target_secondary_date{{i}}"
                             class="ng-trim-ignore color-default" [(ngModel)]="targetSecondary.date" required disabled>
                      <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #dp_secondary disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-7">
                      <input matInput name="proposal_target_secondary_evaluation_criteria{{i}}"
                             placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}"
                             [ngModel]="getCriteriaService().getCriteriaValueByKey(targetSecondary.evaluation_criteria)">
                    </mat-form-field>
                    <mat-form-field class="select-primary col-5" appearance="fill">
                      <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.measure-unit' | translate}}</mat-label>
                      <mat-select name="proposal_target_secondary_criteria_unit{{i}}"
                                  [(ngModel)]="targetSecondary.measure_unit" required>
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()"
                                    [value]="unit.key">{{unit.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-6">
                      <input matInput name="proposal_target_secondary_expected_value{{i}}"
                             placeholder="{{'components.decision-table.new-decision-table.compile-proposal.desidered-value' | translate}}"
                             [(ngModel)]="targetSecondary.expected_value">
                    </mat-form-field>
                    <!-- <i class="icon-cestino grey-color right-bottom" ></i> -->
                  </div>
                </ng-container>
              </div>

              <!-- <div class="col-6 px-2">
                <div class="box-button">
                  <p class="my-0">
                    <i class="icon-Add mx-2"></i>
                    Aggiungi obiettivo secondario
                  </p>
                </div>
              </div> -->
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 2 - Creazione Proposta -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">2</span>
                {{'generic.proposal' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container px-0">
              <div *ngFor="let proposal of model.proposals; let i = index">
                <ng-container>
                  <div class="row">
                    <mat-form-field class="col-12 input-big">
                      <input matInput name="proposal_name{{i}}" [(ngModel)]="proposal.title"
                             placeholder="{{'components.decision-table.new-decision-table.compile-proposal.placeholders.insert-proposal-name' | translate}}"
                             required autocomplete="off">
                    </mat-form-field>
                  </div>
                  <div class="container-fluid accordion-container mt-3 accordion-inner"
                       *ngFor="let criterio of proposal.criteria; let j = index">
                    <div class="container">
                      <mat-accordion class="accordion" multi>
                        <mat-expansion-panel [expanded]="true">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ criterio.title }}
                              <span class="float-right ml-auto font-weight-bold mr-2">
                                        <i
                                          class="icon-circle_info"></i>{{ getCriteriaService().getCriteriaTypeValueByKey(criterio.typology) }}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <!-- Text Area -->
                          <div class="col-12 px-2 mt-5">
                            <div class="box flex-column my-0 pb-0 pt-3 px-3"
                                 *ngIf="!getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">

                              <!-- CAMPO PER QUALITATIVO -->
                              <mat-form-field *ngIf="getCriteriaService().criteriaIsQualitative(criterio.typology)">
                                <mat-label>{{
                                  "generic.description" | translate
                                  }}</mat-label>
                                <textarea
                                  class="description"
                                  matInput
                                  required
                                  resizeToFitContent="true"
                                  name="criterio_description{{ i }}"
                                  placeholder="{{
                                'components.proposals.new-proposal.placeholders.insert-description'
                                  | translate
                              }}"
                                  [(ngModel)]="criterio.value"
                                ></textarea>
                              </mat-form-field>


                              <!-- CAMPO PER QUANTITATIVO -->
                              <ng-container *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)">
                                <div class="flex row">

                                  <mat-form-field class="col-6">
                                    <mat-label>{{
                                      "generic.numeric-value" | translate
                                      }}</mat-label>
                                    <input matInput name="criterio_description{{i}}"
                                           [type]="'number'"
                                           [(ngModel)]="criterio.value" required autocomplete="off"
                                    />
                                  </mat-form-field>
                                  <mat-form-field class="input-primary col-4 text-center">
                                    <mat-label>{{'generic.measure-unit' | translate}}</mat-label>
                                    <input matInput name="criteria_unit{{i}}_{{j}}"
                                           [ngModel]="getUnitsService().getUnitValueByKey(criterio.measure_unit)"
                                           readonly>
                                  </mat-form-field>
                                </div>

                              </ng-container>
                            </div>
                          </div>


                          <ng-container *ngIf="currentOrganization?.customs?.profile == 'enel'">
                            <div class="col-12 d-flex ml-3 my-2">
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="criteria_item_id{{i}}_{{j}}"
                                       [ngModel]="criterio.additional.item_id"
                                       placeholder="{{'generic.item-id' | translate}}" readonly>
                              </mat-form-field>
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="criteria_section{{i}}_{{j}}"
                                       [ngModel]="criterio.additional.section"
                                       placeholder="{{'generic.section' | translate}}" readonly>
                              </mat-form-field>
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="criteria_sub-section{{i}}_{{j}}"
                                       [ngModel]="criterio.additional.sub_section"
                                       placeholder="{{'generic.sub-section' | translate}}" readonly>
                              </mat-form-field>
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="check_n{{i}}_{{j}}" [ngModel]="criterio.additional.check_n"
                                       placeholder="{{'Check_n' | translate}}" readonly>
                              </mat-form-field>
                            </div>
                            <div class="col-12 d-flex ml-3">
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="criteria_preliminary_check{{i}}_{{j}}"
                                       [ngModel]="criterio.additional.preliminary_check"
                                       placeholder="{{'generic.preliminary-check' | translate}}" readonly>
                              </mat-form-field>
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="requirements{{i}}_{{j}}"
                                       [ngModel]="criterio.additional.requirements"
                                       placeholder="{{'generic.requirements' | translate}}" readonly>
                              </mat-form-field>
                              <mat-form-field class="input-primary col-3 pt-2">
                                <input matInput name="reference{{i}}_{{j}}" [ngModel]="criterio.additional.reference"
                                       placeholder="{{'generic.reference' | translate}}" readonly>
                              </mat-form-field>
                            </div>
                          </ng-container>


                          <div class="col-12 px-2" *ngIf="getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">
                            <div class="box flex-column my-0 pb-0 pt-3 px-3">
                              <ng-container>
                                <mat-form-field>
                                  <mat-label>{{
                                    "generic.description" | translate
                                    }}</mat-label>
                                  <textarea
                                    class="description"
                                    matInput
                                    resizeToFitContent="true"
                                    name="criterio_description{{ i }}"
                                    placeholder=""
                                    [(ngModel)]="criterio.value"
                                  ></textarea>
                                </mat-form-field>
                                <div class="row">
                                  <div class="col-12">
                                    <small class="col-12 m-0 p-0 text-muted">{{"generic.options" | translate}}</small>
                                  </div>
                                  <ng-container *ngFor="let option of criterio.options; let o = index">
                                    <div class="col-10 mt-2">
                                      <mat-form-field class="full-width" appearance="fill">
                                        <input style="vertical-align: super;" matInput [(ngModel)]="option.title" name="criteria_options{{o}}" readonly>
                                      </mat-form-field>
                                    </div>
                                    <div class="col-2 mt-2">
                                      <mat-form-field class="full-width" appearance="fill">
                                        <mat-label>{{'generic.weight' | translate}}</mat-label>
                                        <input type="number" matInput [(ngModel)]="option.weight" name="options_weight{{i}}_{{o}}" placeholder="0" readonly>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                          </div>



                          <div class="col-11 mt-4 ">
                            <mat-label class="label-box font-weight-bold" *ngIf="criterio.attachment.length > 0">
                              {{'components.proposals.new-proposal.loaded-evidences' | translate}}
                            </mat-label>

                            <div class="attachment-row"
                                 *ngFor="let attachment of criterio.attachment; let attachIndex = index">
                                      <span class="name">
                                          <i class="icon-pdf"></i>
                                          <a [href]="attachment.link" target="_blank">{{attachment.title}}</a>
                                      </span>
                              <span class="size float-right">
                                          {{'generic.buttons.delete' | translate}}
                                <i class="icon-cestino main-color"
                                   (click)="onClickRemoveEvidence(attachIndex, criterio)"></i>
                                      </span>
                            </div>

                            <!-- {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}} -->
                            <div class="col-12 links-container">
                              <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                                <i class="icon-upload mr-2"></i>
                                {{'generic.buttons.load-evidences' | translate}}
                              </button>
                              <mat-menu #menuAttachment="matMenu">
                                <button mat-menu-item (click)="onClickAddNewEvidence(i, criterio, 'PUBMED')">
                                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                                  Pubmed
                                </button>
                                <button mat-menu-item (click)="onClickAddNewEvidence(i, criterio, 'FILE')">
                                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                                  File
                                </button>
                              </mat-menu>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                  <button type="button" class="col-12 big-button delete my-3" mat-raised-button
                          (click)="removeNewProposal(i)" *ngIf="model.proposals.length > 1">
                    <i class="icon-cestino"></i>
                    {{'components.decision-table.new-decision-table.compile-proposal.remove-proposal' | translate}}
                  </button>
                </ng-container>
              </div>
              <div class="row mt-5">
                <button type="button" class="col-12 big-button mb-3" mat-raised-button (click)="addNewProposal()">
                  <i class="icon-Add"></i>
                  {{'components.decision-table.new-decision-table.compile-proposal.add-proposal' | translate}}
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 3 - Creazione Tavolo -->
    <!-- <div class="accordion-container container-fluid px-0">
      <div class="container-fluid px-0">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="container">
              <mat-panel-title>
                <span class="index">3</span>
                {{'components.decision-table.new-decision-table.compile-proposal.change-evaluation-scale' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- grey section radio -->
    <!-- <div class="container py-4 px-0">
      <div class="box-info mb-4">
        <i class="icon-circle_info float-left col-2 flex-column"></i>
        <p class="description col-10">{{'components.decision-table.new-decision-table.compile-proposal.info-on-scale-modification' | translate}}</p>
      </div>
      <div class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0">
        <div class="container px-0">
          <p class="title">{{'components.decision-table.new-decision-table.compile-proposal.ask-scale-modification' | translate}}</p>
          <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option">
            <mat-grid-list cols="12" rowHeight="100px">
              <ng-container>
                <mat-radio-group disabled="true">
                  <!-- <mat-grid-tile colspan="6" (click)="change_rating_scale = true"> -->
    <!-- <mat-grid-tile colspan="6">
      <mat-radio-button name="change_rating_scale_yes" [value]="true" [checked]="change_rating_scale == true" class="col-12">{{'generic.answers.yes' | translate}}</mat-radio-button>
    </mat-grid-tile>
    <!-- <mat-grid-tile colspan="6" (click)="change_rating_scale = false"> -->
    <!-- <mat-grid-tile colspan="6">
      <mat-radio-button name="change_rating_scale_no" [value]="false" [checked]="change_rating_scale == false" class="col-12">{{'generic.answers.no' | translate}}</mat-radio-button>
    </mat-grid-tile>
  </mat-radio-group>
</ng-container>
</mat-grid-list>
</mat-radio-group>
</div>
</div>
</div> -->

    <!-- white section (Blocco Scala)-->
    <!-- <ng-container *ngIf="change_rating_scale == true">
      <div class="container-fluid box-full-width white" *ngFor="let rating_scale of model.rating_scales; let i = index">
        <ng-container> -->
    <!-- White row titolo e progress bar -->
    <!-- <div class="container pb-2">
      <p class="big-title mb-4">{{'components.decision-table.new-decision-table.compile-proposal.scale-fromto-from' | translate}} {{rating_scale.min_value}} {{'components.decision-table.new-decision-table.compile-proposal.scale-fromto-to' | translate}} {{rating_scale.max_value}}</p>
      <div class="row row-progress-bar">
        <div class="col-1 icon-container text-right"><i class="icon-grid-interface"></i></div>
        <div class="col-10">
          <mat-progress-bar class="progress-bar-primary my-2" mode="determinate" value="60" fill="0"></mat-progress-bar>
        </div>
        <div class="col-1 icon-container">
            <mat-checkbox name="rating_scale_selected{{i}}" [checked]="rating_scale.selected" [(ngModel)]="rating_scale.selected"></mat-checkbox>
        </div>
      </div>
    </div> -->
    <!-- progress bar settings -->
    <!-- <div class="box-shadow-external py-4 box-full-width-inner my-4">
      <div class="container">
        <mat-label class="label-box ml-2 font-weight-bold">{{'components.decision-table.new-decision-table.compile-proposal.setup-evaluation-scale' | translate}}</mat-label>
        <div class="row mt-1">
          <mat-form-field class="select-primary col-4" appearance="fill">
            <mat-label>{{'generic.min-value' | translate}}</mat-label>
            <mat-select name="rating_scale_min{{i}}" [(ngModel)]="rating_scale.min_value" required>
              <mat-option [value]="number+1" [disabled]="rating_scale.max_value && number >= rating_scale.max_value" *ngFor="let number of createArrayOfNumber(0, 100)">{{number+1}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-primary col-4" appearance="fill">
            <mat-label>{{'generic.max-value' | translate}}</mat-label>
            <mat-select name="rating_scale_max{{i}}" [(ngModel)]="rating_scale.max_value" required>
              <mat-option [value]="number+1" [disabled]="number < rating_scale.min_value" *ngFor="let number of createArrayOfNumber(0, 100)">{{number+1}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-primary col-4" appearance="fill">
            <mat-label>{{'generic.step-value' | generic}}</mat-label>
            <mat-select name="rating_scale_step{{i}}" [(ngModel)]="rating_scale.step" required>
              <mat-option [value]="number" [disabled]="rating_scale.max_value && rating_scale.max_value % number !== 0" *ngFor="let number of [1,2,5,10]">{{number}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div> -->
    <!-- Text Area -->
    <!-- <div class="container pt-4">
      <div class="col-12 box flex-column mb-0 pb-0 pt-3 px-3">
        <mat-form-field>
          <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.scale-description' | translate}}</mat-label>
          <textarea class="description" name="rating_scale_description{{i}}" matInput rows="3" [(ngModel)]="rating_scale.description" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.placeholders.insert-scale-description' | translate}}" required></textarea>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</div>
</ng-container> -->

    <!-- Button Area -->
    <!-- <div class="container-fluid box-full-width white pt-2 mbn-16" *ngIf="change_rating_scale == true">
      <div class="container">
        <div class="row">
          <button class="col-12 big-button mb-3" mat-raised-button (click)="addNewRatingScale()">
            <i class="icon-Add"></i>
            {{'components.decision-table.new-decision-table.compile-proposal.add-scale' | translate}}
          </button>
        </div>
      </div>
    </div> -->
    <!--
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div> -->

    <!-- Blocco 4 - Creazione Tavolo -->
<!--    <div class="container-fluid accordion-container" *appHideIfOrganization="'ipsen'">-->
<!--      <div class="container">-->
<!--        <mat-accordion class="accordion" multi>-->
<!--          <mat-expansion-panel expanded>-->
<!--            <mat-expansion-panel-header>-->
<!--              <mat-panel-title>-->
<!--                <span class="index">3</span>-->
<!--                {{'components.decision-table.new-decision-table.compile-proposal.invite-team' | translate}}-->
<!--              </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div class="row justify-content-around">-->
<!--              <div class="col-12 px-0 box box-two-row" *ngFor="let team_member of model.team_members; let i = index">-->
<!--                <app-single-team-member [team_member]="team_member" (removeTeamMemberEvent)="removeTeamMember(i)"/>-->
<!--              </div>-->

<!--              <button class="col-12 big-button my-5" mat-raised-button (click)="addNewTeamMember()">-->
<!--                <i class="icon-Add"></i>-->
<!--                {{'components.decision-table.new-decision-table.compile-proposal.add-team-member' | translate}}-->
<!--              </button>-->
<!--              <button class="col-12 big-button mt-3 mb-5" mat-raised-button (click)="openChartsModal()">-->
<!--                <i class="icon-Add"></i>-->
<!--                {{'components.decision-table.new-decision-table.compile-proposal.import-team-members-from-chart' | translate}}-->
<!--              </button>-->
<!--            </div>-->

<!--          </mat-expansion-panel>-->
<!--        </mat-accordion>-->
<!--      </div>-->
<!--    </div>-->


    <div class="buttons-container container py-4">
      <button class="button-primary float-left" mat-button (click)="backButtonOnClick($event)">
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.back' | translate}}
      </button>
      <button type="submit" class="button-primary float-right" [disabled]="loading" mat-button (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        {{'generic.buttons.save' | translate}}
      </button>
    </div>
  </form>

</ng-container>
