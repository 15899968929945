<div class="container-fluid box-sub-header">
  <div class="container">

    <mat-form-field class="col-12 input-big">
      <input matInput name="title" name="decision_title" value="Nome del progetto"
             [ngModel]="decision_table?.decision_name" readonly>
    </mat-form-field>

    <div class="container mt-2 mb-5" *ngIf="decision_table?.customs?.thankYouPage">
      <span [innerHTML]="decision_table.customs.thankYouPage" ></span>
    </div>

    <mat-form-field class="col-4 select-custom-button" appearance="fill">
      <mat-label>{{'generic.organization' | translate}}</mat-label>
      <input matInput name="organization_name" [ngModel]="organization?.name"
             placeholder="{{'generic.organization' | translate}}" readonly>
    </mat-form-field>

    <mat-form-field class="col-4 datepicker-custom-button" appearance="fill" *ngIf="response_stamps?.completed">
      <mat-label>{{'pages.responses.responses-thank-you.component.send-date' | translate}}</mat-label>
      <input matInput name="evaluation_end" [ngModel]="response_stamps?.last_save| formatdate | date:'medium'" readonly>
    </mat-form-field>

    <div class="col-3 d-inline offset-1" appearance="fill">
      <button class="mt-2" mat-flat-button color="primary"
              disabled>{{'pages.responses.responses-thank-you.component.reminder' | translate}}</button>
    </div>
    <div class="col-12 text-center mt-2" appearance="fill">
      <button class="mt-2" mat-flat-button color="primary" *ngIf="isDecisionCreator"
              [routerLink]="['/decision/' + decision_table.id + '/show']"
              routerLinkActive="router-link-active"
      >{{'generic.buttons.go-to-table' | translate}}</button>
      <button class="mt-2" mat-flat-button color="primary" *ngIf="!isDecisionCreator"
              [routerLink]="['/responses/'+id+'/responses-evaluation']"
              routerLinkActive="router-link-active"
      >{{'components.responses.view_questionnaire_response' | translate}}</button>
    </div>

  </div>
</div>
