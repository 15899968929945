<app-loading [loading]="loading"/>
<div class="container mt-3" *ngIf="!loading">
  <mat-card>
    <mat-stepper [linear]="true" #stepper>
      <!-- Step 1: Title and Description -->
      <mat-step [completed]="true">
        <ng-template matStepLabel>{{ 'generic.details' | translate }}</ng-template>
        <div class="mt-2 text-center row justify-content-center">
          <!-- Immagine dell'organizzazione -->
          <img *ngIf="invitationPage?.decision_table?.organization?.logo_link"
               [src]="invitationPage?.decision_table?.organization?.logo_link"
               alt="organization logo"
               class="organization-logo">
          <!-- Titolo e Descrizione -->
        </div>
        <div class="my-2">
          <div [innerHTML]="invitationPage?.description"></div>
        </div>
        <div class="row justify-content-end my-4">
          <button mat-raised-button matStepperNext color="primary">{{ "generic.next" | translate }}</button>
        </div>
      </mat-step>

      <!-- Step 2: Join Form -->
      <mat-step [stepControl]="form" [completed]="submitted">
        <ng-template matStepLabel>{{ 'generic.join-form' | translate }}</ng-template>
        <form [formGroup]="form" class="join-form my-4">
          <div class="row">
            <mat-form-field appearance="outline" class="form-field col-6">
              <mat-label>{{ "generic.name" | translate }}</mat-label>
              <input matInput formControlName="name" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field col-6">
              <mat-label>{{ "generic.surname" | translate }}</mat-label>
              <input matInput formControlName="surname" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field col-6">
              <mat-label>{{ "generic.email" | translate }}</mat-label>
              <input matInput formControlName="email" required type="email">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field col-6">
              <mat-label>{{ "generic.phone" | translate }}</mat-label>
              <input matInput formControlName="number_phone" [required]="isPhoneNumberMandatory">
              <mat-error *ngIf="form.get('number_phone').hasError('pattern')">
                {{ "errors.invalidPhoneNumber" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row justify-content-between mt-4">
            <button mat-raised-button matStepperPrevious color="accent">{{ "generic.back" | translate }}</button>
            <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">{{ "generic.buttons.save-and-send" | translate }}</button>
          </div>
        </form>
      </mat-step>

      <!-- Step 3: Thank You Page -->
      <mat-step *ngIf="thankYouPageEnabled">
        <ng-template matStepLabel>{{ 'routing.title.responses-thank-you' | translate }}</ng-template>
        <div [innerHTML]="invitationPage?.customs?.thankYouPage" class="my-4"></div>
        <div class="row justify-content-end mt-4">
          <button mat-raised-button color="primary" (click)="done()">{{ "generic.done" | translate }}</button>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-card>
</div>


