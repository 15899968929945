import {Component, Inject, Input} from '@angular/core';
import {ModalResetPasswordComponent} from '../../../modals/modal-reset-password/modal-reset-password.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ModalAddTextComponent} from '../../../modals/modal-add-text/modal-add-text.component';
import {DecisionService} from '../../../services/decision.service';
import {any} from 'codelyzer/util/function';

@Component({
  selector: 'app-decision-table-details',
  templateUrl: './decision-table-details.component.html',
  styleUrls: ['./decision-table-details.component.scss']
})
export class DecisionTableDetailsComponent {
  @Input() decision_table: any;
  @Input() evaluation_session: any;
  @Input() show_indexes = true;
  @Input() isResponse = true;


  constructor(
    private dialog: MatDialog,
    private decisionService: DecisionService,
  ) {
  }

  attachmentsDisplayedColumns = ['name', 'type', 'loaded_at'];

  getAttachmentsByType(type) {
    return this.decision_table?.problem_statement?.attachments.filter((attach) => {
      const isImage = attach.type === 'FILE' && ['jpg', 'png', 'jpeg'].includes(attach.ext.toLowerCase());
      if (type === 'IMAGE') {
        return isImage;
      }
      return !isImage;
    });
  }

  removeThankYouPage() {
    this.decisionService.updateDecisionTable(this.decision_table.id, {thankYouPage: ''}, () => {
      this.decision_table.customs.thankYouPage = null;
    });
  }

  editText(value, field) {
    const data =  {
      value,
      submitFunction: this.decisionService.updateDecisionTable,
      fieldName: field,
      args: [this.decision_table.id],
      title: null,
      successString: null,
    };

    if (field === 'thankYouPage') {
      data.title = 'components.decision-table.show-decision-table.congrats-page-add';
      data.successString = 'components.decision-table.show-decision-table.congrats-page-added-successfully';
    }

    const dialogRef = this.dialog.open(ModalAddTextComponent, {
      panelClass: 'medium-dialog',
      data
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (['thesis', 'context'].includes(field)) {
          this.decision_table.problem_statement[field] = response;
        }
        if (field === 'thankYouPage') {
          if (!this.decision_table?.customs) {
            this.decision_table.customs = {};
          }
          this.decision_table.customs.thankYouPage = response;
        }
      }
    });
  }

  getAttachmentsEvidences() {
    return this.getAttachmentsByType('FILE/PUBMED');
  }

  getAttachmentImages() {
    return this.getAttachmentsByType('IMAGE');
  }
}
