<!-- INIZIO GRAFICI -->

<ng-container *ngIf="surveyService.isOpenedQuestion(question?.typology) && allOpenedContentLoaded">
  <div class="row justify-content-center mt-5">
    <img
      [src]="'https://quickchart.io/wordcloud?height=300&width=600&language=it&removeStopwords=true&fontScale=150&text=' + allOpenedContent">
  </div>
</ng-container>
<ng-container *ngIf="surveyService.isMultipleChoiceQuestion(question?.typology)">
  <div class="container my-5 justify-content-center row">
    <div id="chart_question_id_{{question.id}}" style="width: 200%"></div>
  </div>
</ng-container>
<ng-container *ngIf="surveyService.isCheckboxQuestion(question?.typology)">
  <div class="container my-5 justify-content-center row">
    <div id="chart_question_id_{{question.id}}" style="width: 70%"></div>
  </div>
</ng-container>
<ng-container *ngIf="surveyService.isConsensusQuestion(question?.typology)">

  <div
    class="row"
  >
    <div class="col-6">
      <div
        style="width: 100%"
        id="chart_parameters_div_{{ question.id }}"
      ></div>
    </div>
    <div class="col-6">
      <div
        style="width: 100%"
        id="chart_question_id_{{ question.id }}"
      ></div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="surveyService.isQuantitativeQuestion(question?.typology)">
  <div class="container my-5 justify-content-center row">
    <div id="chart_question_id_{{question.id}}" style="width: 200%"></div>
  </div>
  <div class="container justify-content-center row">
    <div class="col-6">
      <div id="chart_box_plot_question_id_{{question.id}}" style="width: 100%; height: 100%;"></div>
    </div>
    <div class="col-6">

      <mat-table [dataSource]="analyticsFields" class="my-2">
        <ng-container matColumnDef="parameter">
          <mat-header-cell *matHeaderCellDef class="font-weight-bold"> {{ "generic.parameter" | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index"> {{ element.translateString | translate }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef class="font-weight-bold"> {{ "generic.value" | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index"> {{ question[analyticsFields[i].parameter] }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['parameter', 'value']"></mat-header-row>
        <mat-row *matRowDef="let row; columns: ['parameter', 'value']"></mat-row>
      </mat-table>
    </div>
  </div>
</ng-container>

<!-- FINE GRAFICI -->

<!-- INIZIO TABELLA -->

<div class="container my-5">
  <ng-container>
    <mat-table class="table-primary" [dataSource]="responses">
      <!-- Colonna Name (relativamente piccola) -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="small-column">{{ "generic.name" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="small-column">
          {{ showPrivateInfo ? element.name : ('generic.user' | translate) + ' ' + (i + 1) }}
        </mat-cell>
      </ng-container>

      <!-- Colonna Sentiment (relativamente piccola) -->
      <ng-container matColumnDef="sentiment">
        <mat-header-cell *matHeaderCellDef class="small-column">{{ "generic.sentiment" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="small-column">
          <mat-icon *ngIf="!element.customs?.score">sentiment_neutral</mat-icon>
          <mat-icon style="color: #00bc1d" *ngIf="element.customs?.score > 0">sentiment_satisfied_alt</mat-icon>
          <mat-icon style="color: #ff0000" *ngIf="element.customs?.score < 0">sentiment_very_dissatisfied</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Colonna Answer (relativamente più larga) -->
      <ng-container matColumnDef="answer">
        <mat-header-cell *matHeaderCellDef class="large-column">{{ "generic.response" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="large-column">
          <ng-container *ngIf="surveyService.isCheckboxQuestion(question?.typology)">
            <ul>
              <li *ngFor="let choice of element?.choice_answers">{{ choice?.choice?.title }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="surveyService.isMultipleChoiceQuestion(question?.typology)">
            {{ element?.choice_answers[0]?.choice?.title }}
          </ng-container>
          <ng-container *ngIf="!surveyService.isCheckboxQuestion(question?.typology) && !surveyService.isMultipleChoiceQuestion(question?.typology)">
            {{ element.answer }}
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="feedback">
        <mat-header-cell *matHeaderCellDef class="small-column">{{ "generic.feedback" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="small-column">
          <ng-container *ngIf="element.feedback; else elseBlock">{{ element.feedback }}</ng-container>
          <ng-template #elseBlock>N/A</ng-template>
        </mat-cell>
      </ng-container>

      <!-- Colonna Attachments (relativamente piccola) -->
      <ng-container matColumnDef="evidences">
        <mat-header-cell *matHeaderCellDef class="small-column">{{ "generic.attachments" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="small-column">
          <mat-chip-list>
            <mat-chip (click)="element?.attachments?.length > 0 ? showExpertEvideces(element) : ''">
              {{ element?.attachments?.length }}
            </mat-chip>
          </mat-chip-list>
        </mat-cell>
      </ng-container>

      <!-- Definizione delle righe -->
      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
    </mat-table>
  </ng-container>


  <!--  <ng-container-->
  <!--    *ngIf="!surveyService.isConsensusQuestion(question?.typology) && !surveyService.isOpenedQuestion(question?.typology)">-->
  <!--    <mat-table-->
  <!--      [dataSource]="responses" multiTemplateDataRows-->
  <!--      class="mat-elevation-z5 mt-4">-->
  <!--      <ng-container matColumnDef="name">-->
  <!--        <mat-header-cell *matHeaderCellDef>{{ "generic.name" | translate }}</mat-header-cell>-->
  <!--        <mat-cell *matCellDef="let element; let i = renderIndex">-->
  <!--          {{ showPrivateInfo ? element.name : ('generic.user' | translate) + ' ' + (i + 1) }}-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->
  <!--      <ng-container matColumnDef="email">-->
  <!--        <mat-header-cell *matHeaderCellDef>{{ "generic.email" | translate }}</mat-header-cell>-->
  <!--        <mat-cell *matCellDef="let element; let i = renderIndex">-->
  <!--          {{ showPrivateInfo ? element.email : ('generic.email' | translate) + ' ' + (i + 1) }}-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->
  <!--      <ng-container matColumnDef="sentiment">-->
  <!--        <mat-header-cell-->
  <!--          *matHeaderCellDef-->
  <!--          style="justify-content: center"-->
  <!--        >{{ "generic.sentiment" | translate }}-->
  <!--        </mat-header-cell>-->
  <!--        <mat-cell-->
  <!--          *matCellDef="let element"-->
  <!--          style="justify-content: center"-->
  <!--        >-->
  <!--          <mat-icon-->
  <!--            aria-hidden="false"-->
  <!--            *ngIf="!element.customs?.score"-->
  <!--          >sentiment_neutral-->
  <!--          </mat-icon-->
  <!--          >-->
  <!--          <mat-icon-->
  <!--            aria-hidden="false"-->
  <!--            style="color: #00bc1d"-->
  <!--            *ngIf="element.customs?.score > 0"-->
  <!--          >sentiment_satisfied_alt-->
  <!--          </mat-icon-->
  <!--          >-->
  <!--          <mat-icon-->
  <!--            aria-hidden="false"-->
  <!--            style="color: #ff0000"-->
  <!--            *ngIf="element.customs?.score < 0"-->
  <!--          >sentiment_very_dissatisfied-->
  <!--          </mat-icon-->
  <!--          >-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->
  <!--      <ng-container matColumnDef="evidences">-->
  <!--        <mat-header-cell *matHeaderCellDef>{{ "generic.attachments" | translate }}</mat-header-cell>-->
  <!--        <mat-cell-->
  <!--          *matCellDef="let element"-->
  <!--        >-->
  <!--          <mat-chip-list-->
  <!--          >-->
  <!--            <mat-chip (click)="element?.attachments?.length > 0 ? showExpertEvideces(element) : ''">-->
  <!--              {{-->
  <!--                element?.attachments?.length-->
  <!--              }}-->
  <!--            </mat-chip>-->
  <!--          </mat-chip-list-->
  <!--          >-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->
  <!--      <ng-container matColumnDef="action">-->
  <!--        <mat-header-cell *matHeaderCellDef>{{ "generic.action" | translate }}</mat-header-cell>-->
  <!--        <mat-cell *matCellDef="let element">-->
  <!--          <button class="my-2" mat-button-->
  <!--                  color="primary">-->
  <!--            <small>{{ 'generic.details' | translate }}</small>-->
  <!--            <mat-icon class="ml-1" *ngIf="element == expandedElement">arrow_upward</mat-icon>-->
  <!--            <mat-icon class="ml-1" *ngIf="element !== expandedElement">arrow_downward</mat-icon>-->
  <!--          </button>-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->

  <!--      &lt;!&ndash; Expanded Content Column - The detail row is made up of this one column that spans across all columns &ndash;&gt;-->
  <!--      <ng-container matColumnDef="expandedDetail">-->
  <!--        <mat-cell *matCellDef="let element" class="element-detail">-->
  <!--          <div class="w-100"-->
  <!--               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">-->
  <!--            <div class="container">-->
  <!--              <div class="my-3">-->
  <!--                <span class="font-weight-bold text-center">{{ "generic.response" | translate }}: </span>-->
  <!--                <ng-container *ngIf="element?.status === 'ABSTAINED'">-->
  <!--                  <span>{{ "components.responses.consensus.abstained" | translate }}</span>-->
  <!--                </ng-container>-->
  <!--                <ng-container *ngIf="element?.status == 'ANSWERED'">-->
  <!--                  <ng-container-->
  <!--                    *ngIf="surveyService.isCheckboxQuestion(question.typology) || surveyService.isMultipleChoiceQuestion(question.typology)">-->
  <!--                    <br>-->
  <!--                    <div class="container">-->
  <!--                      <div class="row" *ngFor="let choice_answer of element.choice_answers">-->
  <!--                        <div class="col-12">-->
  <!--                          <mat-checkbox disabled checked>-->
  <!--                            <span>{{ choice_answer?.choice?.title }}</span></mat-checkbox>-->
  <!--                        </div>-->
  <!--                      </div>-->
  <!--                    </div>-->
  <!--                  </ng-container>-->
  <!--                  <ng-container *ngIf="surveyService.isQuantitativeQuestion(question?.typology)">-->
  <!--                    <span>{{ element.answer }}</span>-->
  <!--                    <mat-slider-->
  <!--                      class="w-100 mt-3 mb-4 {{ getCustoms.tricolor ? 'tricolor' : '' }} cdk-focused"-->
  <!--                      [max]="getCustoms.max"-->
  <!--                      [min]="getCustoms.min"-->
  <!--                      disabled-->
  <!--                      [thumbLabel]="true"-->
  <!--                      [value]="element.answer"-->
  <!--                      color="primary"-->
  <!--                    />-->
  <!--                  </ng-container>-->
  <!--                  <ng-container-->
  <!--                    *ngIf="!surveyService.isCheckboxQuestion(question.typology) && !surveyService.isMultipleChoiceQuestion(question?.typology)">-->
  <!--                    <span>{{ element.answer }}</span>-->
  <!--                  </ng-container>-->
  <!--                </ng-container>-->
  <!--              </div>-->
  <!--              <ng-container *ngIf="element?.feedback">-->
  <!--                <div class="my-3">-->
  <!--                  <span class="font-weight-bold text-center">{{ "generic.feedback-name" | translate }}: </span>-->
  <!--                  <span>{{ element.feedback }}</span>-->
  <!--                </div>-->
  <!--              </ng-container>-->
  <!--              <ng-container *ngIf="surveyService.isConsensusQuestion(question.typology)">-->
  <!--                <div class="my-3">-->
  <!--                  <span class="font-weight-bold text-center">{{ "generic.confidence-degree" | translate }}: </span>-->
  <!--                  <span>{{ element.customs.confidence }}</span>-->
  <!--                </div>-->
  <!--              </ng-container>-->
  <!--              <ng-container *ngIf="surveyService.isOpenedQuestion(question.typology)">-->
  <!--                <div class="my-3">-->
  <!--                  <span class="font-weight-bold text-center">{{ "generic.wordcloud" | translate }}: </span>-->
  <!--                </div>-->
  <!--                <div class="row justify-content-center mt-5">-->
  <!--                  <img-->
  <!--                    [src]="'https://quickchart.io/wordcloud?height=300&width=600&language=it&removeStopwords=true&fontScale=150&text=' + answerWordCloudContent(element)">-->
  <!--                </div>-->
  <!--              </ng-container>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </mat-cell>-->
  <!--      </ng-container>-->

  <!--      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>-->
  <!--      <mat-row *matRowDef="let element; columns: columnsToDisplay;"-->
  <!--               class="example-element-row"-->
  <!--               [class.example-expanded-row]="expandedElement === element"-->
  <!--               (click)="expandedElement = expandedElement === element ? null : element">-->
  <!--      </mat-row>-->
  <!--      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>-->
  <!--    </mat-table>-->
  <!--  </ng-container>-->
</div>

<!-- FINE TABELLA -->
