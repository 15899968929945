import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.userService.userInfo && this.userService.userLoggedIn) {

      // Gli utenti GUEST possono accedere solo alle routes delle responses
      const ALLOWED_ROUTES_TO_GUEST_USER  = [
        'responses',
        'guest',
        'profile',
        'contests/propose',
        '/organization/' + this.userService.userDetails.current_organization_id + '/user/' + this.userService.getCurrentOrganizationUser()?.id,
      ]
      if (
        !ALLOWED_ROUTES_TO_GUEST_USER.some((route) => state.url.includes(route)) &&
        this.userService.getCurrentOrganizationUser().role === "GUEST"
      ) {
        this.router.navigate(['/guest']);
        return false;
      }

      return true;
    }


    this.router.navigate(['/'], {queryParams: {returnUrl: state.url.split(";")[0]}});
    return false;
  }

}
