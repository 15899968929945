<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <button class="anonymize-button" *ngIf="suggestedProposals?.length > 0 && enabledSuggestedProposalsButton" (click)="showSuggestedProposals()">
    {{ 'components.responses.view-suggested-proposals' | translate }}
  </button>
  <div class="container">
    <!-- Sezione alert -->
    <ng-container *ngIf="!loading">
      <mat-card *ngIf="decision_table?.closing_date" class="my-3" style="background-color: red; color: white;">
        {{'components.responses.decision-table-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="!evaluation_session?.collecting && !decision_table?.closing_date" class="my-3" style="background-color: red; color: white;">
        {{'components.decision-session.decision-session-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="response_stamps?.completed" class="my-3" style="background-color: red; color: white;">
        {{'components.responses.responses-already-sent' | translate}}
      </mat-card>
    </ng-container>

      <mat-form-field class="col-12 col-lg-4 select-custom-button" appearance="fill">
          <mat-label>{{'generic.organization' | translate}}</mat-label>
          <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-4 datepicker-custom-button" appearance="fill">
          <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
          <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : translate('components.responses.undefined-due-date')" placeholder="{{'components.responses.due-date' | translate}}" readonly>
      </mat-form-field>
  </div>
</div>

<div class="container-fluid my-1 bg-white">
  <div class="row container mx-auto">
      <div class="stepper-container col-12 my-4 pb-4">
          <div class="timeline">
              <div class="step-container">
                  <div class="step">1</div>
              </div>
              <div class="step-container">
                  <div class="step">2</div>
              </div>
              <div class="step-container current">
                  <div class="step">3</div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'generic.evaluation' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- filtri per l'organizzazione di enel -->
              <ng-container>
                <div class="row">
                  <div class="col-12 col-lg-6 px-0 mb-0">
                    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                        <mat-label class="box-title px-3 mb-0">{{'generic.filters' | translate}}</mat-label>
                    </div>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <mat-form-field class="select-primary col-3" appearance="fill" *appShowIfOrganization="'enel,alexion'">
                    <mat-label>{{'generic.section-filter' | translate}}</mat-label>
                    <mat-select name="filter_section" [(ngModel)]="filters.section_selected" (selectionChange)="filterOnChange('section')">
                      <mat-option value="" selected>ALL</mat-option>
                      <mat-option *ngFor="let sec of filters.section_filtered" [value]="sec">{{sec}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-3" appearance="fill" *appShowIfOrganization="'enel,alexion'">
                    <mat-label>{{'generic.subsection-filter' | translate}}</mat-label>
                    <mat-select name="filter_subsection" [(ngModel)]="filters.subsection_selected" (selectionChange)="filterOnChange('subsection')">
                      <mat-option value="" selected>ALL</mat-option>
                      <mat-option *ngFor="let subsec of filters.subsection_filtered" [value]="subsec">{{subsec}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <section style="padding: 0.75em 0.75em 0 0.75em;">
                    <mat-checkbox class="col-3" [(ngModel)]="onlyNotResponsed" (change)="filterResponsed()">{{'generic.only-answered' | translate}}</mat-checkbox>
                  </section>
                  <section style="padding: 0.75em 0.75em 0 0.75em;">
                    <mat-checkbox class="col-3" [(ngModel)]="exandedPanels">{{'pages.responses.filters.show-all-open' | translate}}</mat-checkbox>
                  </section>
                </div>
              </ng-container>

              <div class="row">
                  <div class="col-12 col-lg-6 px-0 mb-0" *ngIf="framework_criteria.length > 0">
                      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0">{{'generic.proposal' | translate}}</mat-label>
                      </div>
                  </div>

                  <!-- <div cdkScrollable class="virtual-viewport"> -->
                    <ng-container *ngFor="let criteria of framework_criteria; let criteria_index = index">
                      <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left" *ngIf="criteria.hidden == false && criteria.hidden_only_response == false">

                        <!-- Criterio qualitativo -->
                        <div class="container" *ngIf="getCriteriaService().criteriaIsQualitative(criteria.typology)">
                            <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                <mat-expansion-panel class="px-0" [expanded]="exandedPanels">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>{{criteria.title}}</span>
                                            <i *ngIf="!response_stamps?.completed" class="icon-circle_info d-flex"></i>
                                            <i *ngIf="!response_stamps?.completed" class="icon-checkmark abs-tr mx-0" [ngClass]="getIconColorForProposalCriteria(criteria)"></i>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <div class="row border-bottom-grey pt-0 pb-4 my-3" *ngFor="let proposal of criteria.proposals; let proposal_index = index">
                                        <small class="col-12">{{'generic.evaluation' | translate}} {{proposal.title}}
                                          <mat-label class="box-title px-3 mb-0 label-box">
                                            <span class="float-right" *ngIf="evaluation_session?.collecting && !decision_table?.closing_date && !response_stamps?.completed && evaluation_session.settings.review_enabled">
                                <button mat-button class="main-color" (click)="suggestProposalOnClick(proposal)">
                                    <i class="icon-re_edit mr-2"></i>
                                  {{'components.responses.revision-response' | translate}}
                                </button></span></mat-label>
                                        </small>
                                        <div class="col-12" *ngIf="proposal.evaluation_criteria">{{proposal.evaluation_criteria.value}}</div>
                                        <!-- Allegati da chi ha aperto il tavolo -->
                                        <div class="col-12 mt-3" *ngIf="proposal?.evaluation_criteria?.attachment?.length > 0">
                                          <mat-label class="label-box font-weight-bold">
                                            {{'components.responses.loaded-evidences' | translate}}
                                          </mat-label>
                                        </div>
                                        <div class="col-12 mb-3" *ngFor="let attachment of proposal?.evaluation_criteria?.attachment">
                                          <div class="attachment-row">
                                              <span class="name">
                                                <i class="icon-pdf"></i>
                                                <a [href]="attachment.link" target="_blank" (click)="onClickAttachment(attachment)">{{attachment.title}}</a>
                                              </span>
                                            </div>
                                        </div>
                                      <mat-slider [disabled]="response_stamps?.completed" *ngIf="!proposal.evaluation_criteria.answered" class="w-100 mt-2 tricolor cdk-focused {{ organization?.customs?.profile === 'ipsen' ? 'custom-slider' : ''}}" min="0" max="100" step="1" [thumbLabel]="true" [(ngModel)]="proposal.evaluation.weight" color="primary"></mat-slider>
                                      <mat-slider disabled *ngIf="proposal.evaluation_criteria.answered" class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [thumbLabel]="true" [value]="proposal.evaluation_criteria.response.confidence" color="primary"></mat-slider>
                                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                          <small class="float-left">{{'components.responses.very-low' | translate}}</small>
                                          <small class="float-right">{{'components.responses.very-high' | translate}}</small>
                                        </div>
                                        <div class="col-12 mt-5">
                                          <mat-form-field class="full-width" appearance="fill">
                                            <mat-label class="label-box font-weight-bold">
                                              {{'generic.feedback' | translate}}
                                            </mat-label>
                                            <textarea [disabled]="response_stamps?.completed" *ngIf="!proposal.evaluation_criteria.answered" class="description my-2" required matInput rows="3" [(ngModel)]="proposal.evaluation_criteria.answer.feedback"></textarea>
                                            <textarea disabled *ngIf="proposal.evaluation_criteria.answered" class="description my-2" required matInput rows="3" [value]="proposal.evaluation_criteria.response.feedback"></textarea>

                                          </mat-form-field>
                                        </div>
                                        <div class="col-12 links-container"  *ngIf="!response_stamps?.completed">
                                          <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                                            <i class="icon-upload mr-2"></i>
                                            {{'components.responses.add-evidences' | translate}}
                                          </button>
                                          <mat-menu #menuAttachment="matMenu">
                                            <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'PUBMED')">
                                              <mat-icon aria-hidden="false">file_upload</mat-icon> Pubmed
                                            </button>
                                            <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'FILE')">
                                              <mat-icon aria-hidden="false">file_upload</mat-icon> File
                                            </button>
                                          </mat-menu>
                                      </div>
                                      <div class="col-12" *ngIf="proposal?.evaluation_criteria?.attachment_expert?.length > 0">
                                        <mat-label class="label-box font-weight-bold">
                                          {{'components.responses.evidences-loaded-by-expert' | translate}}
                                        </mat-label>
                                      </div>
                                      <div class="col-12" *ngFor="let attachment_expert of proposal?.evaluation_criteria?.attachment_expert; let attachIndex = index">
                                        <div class="attachment-row">
                                            <span class="name">
                                              <i class="icon-pdf"></i>
                                              <a [href]="attachment_expert.link" target="_blank">{{attachment_expert.title}}</a>
                                            </span>
                                          <span class="size float-right" *ngIf="!decision_table?.closing_date && evaluation_session?.collecting && !response_stamps?.completed">
                                              {{'generic.buttons.delete' | translate}}
                                            <i class="icon-cestino main-color" (click)="onClickRemoveEvidence(attachIndex, proposal.evaluation_criteria)"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row border-bottom-grey pt-0 pb-4 my-3">
                                      <small class="col-12">{{'components.responses.ask-confidence' | translate}}</small>
                                      <mat-slider [disabled]="response_stamps?.completed" class="w-100 mt-2 cdk-focused {{ organization?.customs?.profile === 'ipsen' ? 'custom-slider' : ''}}" min="0" max="100" step="1" [(ngModel)]="criteria.proposals[0].evaluation_criteria.answer.confidence" (click)="setCriteriaAnswerConfidence(criteria.proposals[0].evaluation_criteria)"  [thumbLabel]="true" color="primary"></mat-slider>
                                      <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                        <small class="float-left">{{'components.responses.absolutely-not-sure' | translate}}</small>
                                        <small class="float-right">{{'components.responses.absolutely-sure' | translate}}</small>
                                    </div>
                                  </div>

                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>

                        <!-- Criterio a scelta multipla -->
                      <div class="container" *ngIf="getCriteriaService().criteriaIsMultipleChoice(criteria.typology)">
                        <mat-accordion class="accordion" multi [togglePosition]="'before'">
                          <mat-expansion-panel class="px-0" [expanded]="exandedPanels">
                              <mat-expansion-panel-header>
                                  <mat-panel-title>
                                      <span>{{criteria.title}}</span>
                                      <i class="icon-circle_info text-white d-flex"></i>
                                      <i class="icon-checkmark d-flex abs-tr mx-0" [ngClass]="getIconColorForProposalCriteria(criteria)"></i>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>

                              <div class="row border-bottom-grey pt-0 pb-4 my-3" *ngFor="let proposal of criteria.proposals; let proposal_index = index">
                                  <div class="col-12 mt-3" *ngIf="proposal?.evaluation_criteria?.attachment?.length > 0">
                                    <mat-label class="label-box font-weight-bold">
                                      {{'components.responses.loaded-evidences' | translate}}
                                    </mat-label>
                                  </div>
                                  <div class="col-12 mb-3" *ngFor="let attachment of proposal?.evaluation_criteria?.attachment">
                                    <div class="attachment-row">
                                        <span class="name">
                                          <i class="icon-pdf"></i>
                                          <a [href]="attachment.link" target="_blank" (click)="onClickAttachment(attachment)">{{attachment.title}}</a>
                                        </span>
                                      </div>
                                  </div>
                                  <div class="row" *appShowIfOrganization="'enel,alexion'">
                                    <div class="col-12">
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.section" placeholder="{{'generic.section' | translate}}" readonly>
                                      </mat-form-field>
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.sub_section" [matTooltip]="proposal.evaluation_criteria?.additional?.sub_section" [matTooltipPosition]="'below'" placeholder="{{'generic.sub-section' | translate}}" readonly>
                                      </mat-form-field>
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.check_n" placeholder="{{'Check_n' | translate}}" readonly>
                                      </mat-form-field>
                                    </div>

                                    <div class="col-12">
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.preliminary_check" [matTooltip]="proposal.evaluation_criteria?.additional?.preliminary_check" [matTooltipPosition]="'below'" placeholder="{{'generic.preliminary-check' | translate}}" readonly>
                                      </mat-form-field>
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.requirement" [matTooltip]="proposal.evaluation_criteria?.additional?.requirement" [matTooltipPosition]="'below'" placeholder="{{'generic.requirements' | translate}}" readonly>
                                      </mat-form-field>
                                      <mat-form-field class="input-primary col-4">
                                        <input matInput [ngModel]="proposal.evaluation_criteria?.additional?.reference" placeholder="{{'generic.reference' | translate}}" readonly>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                  <ng-container *ngIf="proposal.evaluation_criteria.options">
                                    <mat-radio-group [(ngModel)]="proposal.evaluation_criteria.answer.option_value" style="display: flex; flex-direction: column; margin: 15px 0;">
                                      <mat-radio-button *ngFor="let option of proposal.evaluation_criteria.options" [value]="option.id" class="ml-1 mt-2" (change)="optionSelected($event, proposal.evaluation_criteria)">
                                          <span style="white-space: normal">{{option.title}}</span>
                                      </mat-radio-button>
                                    <button class="mt-3 text-danger border" mat-button (click)="removeSelection(proposal.evaluation_criteria)">Cancel Selection</button>
                                    </mat-radio-group>
                                    <!-- TODO_TRADUZIONI -->
                                  </ng-container>

                                  <div class="col-12 mt-1">
                                    <mat-form-field class="full-width" appearance="fill">
                                      <mat-label class="label-box font-weight-bold">
                                        {{'generic.feedback' | translate}}
                                      </mat-label>
                                      <textarea class="description my-2" required matInput rows="3" [(ngModel)]="proposal.evaluation_criteria.answer.feedback"></textarea>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-12" *ngIf="organization?.customs?.profile == 'enel'">
                                    <mat-form-field class="full-width" appearance="fill">
                                      <mat-label class="label-box font-weight-bold">
                                        {{'generic.draft-action' | translate}}
                                      </mat-label>
                                      <textarea class="description my-2" matInput rows="3" [(ngModel)]="proposal.evaluation_criteria.answer.draft_action"></textarea>
                                    </mat-form-field>
                                  </div>
                                <div class="col-12 links-container"  *ngIf="!response_stamps?.completed">
                                  <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment" *ngIf="!isEnel()">
                                    <i class="icon-upload mr-2"></i>
                                    {{'components.responses.add-evidences' | translate}}
                                  </button>
                                  <mat-menu #menuAttachment="matMenu">
                                    <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'PUBMED')">
                                      <mat-icon aria-hidden="false">file_upload</mat-icon> Pubmed
                                    </button>
                                    <button mat-menu-item (click)="onClickAddNewEvidence(proposal_index, criteria_index, 'FILE')">
                                      <mat-icon aria-hidden="false">file_upload</mat-icon> File
                                    </button>
                                  </mat-menu>
                                </div>
                                <div class="col-12" *ngIf="proposal?.evaluation_criteria?.attachment_expert?.length > 0">
                                  <mat-label class="label-box font-weight-bold">
                                    {{'components.responses.evidences-loaded-by-expert' | translate}}
                                  </mat-label>
                                </div>
                                <div class="col-12" *ngFor="let attachment_expert of proposal?.evaluation_criteria?.attachment_expert; let attachIndex = index">
                                  <div class="attachment-row">
                                            <span class="name">
                                              <i class="icon-pdf"></i>
                                              <a [href]="attachment_expert.link" target="_blank">{{attachment_expert.title}}</a>
                                            </span>
                                    <span class="size float-right" *ngIf="!decision_table?.closing_date && evaluation_session?.collecting && !response_stamps?.completed">
                                              {{'generic.buttons.delete' | translate}}
                                      <i class="icon-cestino main-color" (click)="onClickRemoveEvidence(attachIndex, proposal.evaluation_criteria)"></i>
                                          </span>
                                  </div>
                                </div>
                              </div>
                              <div class="row border-bottom-grey pt-0 pb-4 my-3" *ngIf="organization?.customs?.profile != 'enel'">
                                <small class="col-12">{{'components.responses.ask-confidence' | translate}}</small>
                                <mat-slider class="w-100 mt-2 cdk-focused {{ organization?.customs?.profile === 'ipsen' ? 'custom-slider' : ''}}" min="0" max="100" step="1" [(ngModel)]="criteria.proposals[0].evaluation_criteria.answer.confidence" (click)="setCriteriaAnswerConfidence(criteria.proposals[0].evaluation_criteria)" [thumbLabel]="true" color="primary"></mat-slider>
                                <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                  <small class="float-left">{{'components.responses.absolutely-not-sure' | translate}}</small>
                                  <small class="float-right">{{'components.responses.absolutely-sure' | translate}}</small>
                              </div>
                            </div>

                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </ng-container>
                  <!-- </div> -->

                  <div class="col-12 links-container"  *ngIf="evaluation_session?.collecting && !decision_table?.closing_date && !response_stamps?.completed && evaluation_session.settings.provide_new_proposal_enabled">

                    <!-- TODO_MICHELE: aggiungere l'aggiunta di una nuova proposta solo da opzioni del tavolo decisionale -->
                    <button mat-button color="primary" (click)="suggestProposalOnClick()">
                      <i class="icon-Add mr-2"></i>
                      {{'components.responses.ask-add-new-proposal' | translate}}
                    </button>

                    <!-- TODO_MICHELE: aggiungere la revisione delle proposte -->
                      <!-- <button mat-button color="primary">
                          <i class="icon-re_edit mr-2"></i>
                          Vuoi revisionare le proposte?
                      </button> -->
                  </div>

              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<div class="container-fluid mt-4">
  <!-- <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
      <button class="px-5" mat-flat-button color="primary">Visualizza riepilogo</button>
  </div> -->
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto" *ngIf="!response_stamps?.completed">
      <button class="px-5" mat-flat-button color="primary" [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit(true)" >{{'generic.buttons.save-draft' | translate}}</button>
  </div>
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
    <div *ngIf="response_stamps">
      <span *ngIf="response_stamps?.completed"> {{'components.responses.info-sent-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
      <span *ngIf="!response_stamps?.completed"> {{'components.responses.info-draft-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
    </div>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4" *ngIf="!response_stamps?.completed">
  <button class="button-primary float-left" mat-button [disabled]="!evaluation_session?.collecting || loading" [routerLink]="[routeBack]">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.go-back' | translate}}
  </button>
  <button class="button-primary float-right" mat-button [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit()">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save-and-send' | translate}}
  </button>
</div>





<!-- Parte dedicata all'aggiunta degli allegati -->

<!-- <div class="row pt-4 pb-0 my-3">
  <small class="col-12">"{{'generic.evaluation' | translate}}"</small>
  <div class="col-12">Rispetto dei tempi</div>
  <mat-slider class="w-100 mt-2 tricolor cdk-focused" min="0" max="10" step="1" value="3" color="primary" [thumbLabel]="true"></mat-slider>
  <div class="d-flex justify-content-between w-100 mb-2 px-2">
      <small class="float-left">Poco importante</small>
      <small class="float-right">Molto importante</small>
  </div>

  <div class="col-12 mt-5">
      <mat-label class="label-box font-weight-bold">
          Feedback
          <i class="icon-cestino grey-color abs-tr"></i>
      </mat-label>
      <p class="my-2">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.
      </p>
  </div>

  <div class="col-12 mt-3 ">
      <mat-label class="label-box font-weight-bold">
          Evidenze caricate
      </mat-label>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="attachment-row">
          <span class="name">
              <i class="icon-pdf"></i>
              Product_3.jpg
          </span>
          <span class="size float-right">
              12.3 MB
              <i class="icon-Cancel main-color"></i>
          </span>
      </div>

      <div class="col-12 links-container">
          <button mat-button color="primary">
              <i class="icon-upload mr-2"></i>
              {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}}
          </button>
      </div>
  </div>

</div> -->
