import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class JoinRequestService {
  constructor(private backend: BackendService, private translateService: TranslateService) {}

  public getAllJoinRequests(id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.get(`decision/${id}/join-requests`).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public getJoinRequest(id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.get(`join-request/${id}`).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public createJoinRequest(model: any, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.post('join-request/', model).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public updateJoinRequest(id: string, model: any, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.put(`join-request/${id}/`, model).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public deleteJoinRequest(id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.destroy(`join-request/${id}`).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public approveJoinRequest(id: string, model: any, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.post(`join-request/${id}/approve`, model).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public refuseJoinRequest(id: string, model: any, success_callback = (data: any) => {}, error_callback = (error: any) => {}) {
    this.backend.post(`join-request/${id}/refuse`, model).subscribe(
      result => success_callback(result),
      error => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }
}
