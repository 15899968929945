import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../../services/decision.service';
import {FrameworkService} from '../../../services/framework.service';
import {UnitsService} from '../../../services/config/units.service';
import {CriteriaService} from '../../../services/config/criteria.service';
import {AnalysisMethodsService} from '../../../services/config/analysis-methods.service';
import {ToastService} from '../../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-criteria-targets-weight-update',
  templateUrl: './criteria-targets-weight-update.component.html',
  styleUrls: ['./criteria-targets-weight-update.component.scss'],
})
export class CriteriaTargetsWeightUpdateComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private decisionService: DecisionService,
    private unitService: UnitsService,
    private criteriaService: CriteriaService,
    public analysisMethodService: AnalysisMethodsService,
    private toast: ToastService,
    private translateService: TranslateService,
  ) {
  }

  get allTargetByTypology() {
    return this.isMulticriteria ? [this.model.primary_target] : this.model?.secondary_targets;
  }

  get isMulticriteria() {
    const analisysType = this.decisionTable?.framework?.analysis_type || this.framework_model.analysis_type;
    return this.analysisMethodService.isMulticriteriaByType(analisysType);
  }

  get isMultiobjectiveFramework() {
    const analisysType = this.decisionTable?.framework?.analysis_type || this.framework_model.analysis_type;
    return this.analysisMethodService.isMultiobjectiveByType(analisysType);
  }


  get getCriteriaWeightsSum() {
    return this.model ?
      this.model.primary_target.criteria
        .map((criteria: any) => criteria.evaluation?.weight || 0)
        .reduce((acc: number, weight: number) => acc + weight, 0)
      : 0;

    // const secondaryTargetWeightSum = this.model.secondary_targets
    //   .flatMap((secondary: any) => secondary.criteria)
    //   .map((criteria: any) => criteria.evaluation?.weight || 0)
    //   .reduce((acc: number, weight: number) => acc + weight, 0);
    //
    // return [primaryTargetWeightSum, secondaryTargetWeightSum];
  }

  @Input() decisionTable: any = null;
  @Input() stringify_framework_model: any = null;
  @Input() editMode = true;
  @Input() evaluation_session_id = false;
  @Output() weightsUpdated: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') f;
  @ViewChild('weightsUpdate', {static: true}) weightsUpdate!: ElementRef;

  isInView = false;
  loading = false;
  criteriaAndTargetsLoaded = false;
  initialModel = {
    primary_target: null,
    secondary_targets: [],
  };
  model = null;
  framework_model: any = null;
  weights_update = null;
  private initTimeout: any = null;

  protected readonly Math = Math;

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    if (!this.decisionTable) {
      this.f.form.valueChanges.subscribe((change) => {
        this.weightsUpdated.emit(this.model);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    clearTimeout(this.initTimeout);
    if (!this.decisionTable) {
      this.initTimeout = setTimeout(() => {
        this.init();
      }, 1300);
    }
  }

  init() {
    this.framework_model = JSON.parse(this.stringify_framework_model);
    this.loading = true;
    this.getTargets();
  }

  getTargets() {
    // Se siamo in decision table
    if (this.decisionTable) {
      this.decisionService.getTableTargetAndCriteriasWithWeights(this.decisionTable?.id, undefined, (result) => {
        this.weights_update = result;
        this.initialModel.primary_target = this.decisionTable?.targets?.find((target) => target?.type === 'primary');
        this.initialModel.secondary_targets = this.decisionTable?.targets?.filter((target) => target?.type === 'secondary');
        this.decisionTable.framework?.criteria?.forEach((criterion) => {
          criterion.evaluation = {
            weight: 0
          };
        });

        this.initialModel.primary_target.evaluation = {
          weight: result?.targets?.find((target) => target.target_id === this.initialModel.primary_target.id)?.weight
        };
        this.initialModel.primary_target.criteria = this.decisionTable?.framework?.criteria;

        this.initialModel.secondary_targets?.forEach((tmp) => {
          tmp.evaluation = {
            weight: result?.targets?.find((target) => target.target_id === tmp.id)?.weight
          };
          tmp.criteria = this.decisionTable?.framework?.criteria;
        });
        this.model = JSON.parse(JSON.stringify(this.initialModel));
        this.loading = false;
        this.criteriaAndTargetsLoaded = true;
        this.setUpdateWeights();
        this.model = JSON.parse(JSON.stringify(this.model));
      });
    }

    // Se siamo in framework
    if (this.framework_model) {
      const framework_model = JSON.parse(JSON.stringify(this.framework_model));
      const criteria = framework_model.criteria;
      criteria.forEach((criterion) => {
        criterion.evaluation = {
          weight: 0
        };
      });
      framework_model.primary_target.criteria = criteria;
      framework_model.primary_target.evaluation = {
        weight: 0,
      };
      this.initialModel.primary_target = framework_model.primary_target;
      this.initialModel.secondary_targets = this.framework_model?.secondary_targets.map((target) => {
        return {
          ...target,
          criteria,
          evaluation: {
            weight: 0
          },
        };
      });
      this.model = JSON.parse(JSON.stringify(this.initialModel));
      this.loading = false;
      this.criteriaAndTargetsLoaded = true;
    }
  }

  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  submit() {
    if (!this.editMode) {
      return;
    }

    if (this.getCriteriaWeightsSum !== 100) {
      this.toast.error(this.translateService.instant('generic.error'), this.translateService.instant('modals.modal-edit-weights.weights-total-must-be-100'));
      return;
    }

    const model = [...this.allTargetByTypology];

    this.loading = true;
    this.decisionService.addTableTargetAndCriteriasWithWeights(this.decisionTable.id, this.model, (result) => {
      this.toast.success(this.translateService.instant('generic.weights'), this.translateService.instant('modals.modal-edit-weights.weights-added-successfully'));
      this.weightsUpdated.emit(model);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  setUpdateWeights() {
    if (this.isMulticriteria) {
      this.weights_update?.single_weight_updates?.forEach((single_weight) => {
        this.model?.primary_target.criteria.forEach((criterion) => {
          if (single_weight?.weight_update_type === 'criterion' && single_weight?.object_id === criterion.id && single_weight?.compared_item_id === this.model?.primary_target.id) {
            criterion.evaluation.weight = single_weight.weight;
          }
        });
        if (single_weight?.weight_update_type === 'target' && single_weight?.object_id === this.model?.primary_target.id) {
          this.model.primary_target.evaluation.weight = single_weight.weight;
        }
      });
    } else {
      this.model?.secondary_targets?.forEach((target) => {
        this.weights_update?.single_weight_updates?.forEach((single_weight) => {
          target.criteria.forEach((criterion) => {
            if (single_weight?.weight_update_type === 'criterion' && single_weight?.object_id === criterion.id && single_weight?.compared_item_id === target.id) {
              criterion.evaluation.weight = single_weight.weight;
            }
          });
          if (single_weight?.weight_update_type === 'target' && single_weight?.object_id === target.id) {
            target.evaluation.weight = single_weight.weight;
          }
        });
      });
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const rect = this.weightsUpdate.nativeElement.getBoundingClientRect();
    this.isInView = rect.top < window.innerHeight && rect.bottom > 0;
  }

  getDashArray() {
    const limitedPercentage = Math.min(this.getCriteriaWeightsSum, 100);
    const circumference = 2 * Math.PI * 40; // 40 è il raggio del cerchio nel d
    return `${(circumference * limitedPercentage) / 100}, ${circumference}`;
  }
}
