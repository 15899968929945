<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'generic.create-new-contest' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container">
  <mat-card class="w-75 my-5 ml-auto mr-auto">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width">
          <mat-label>Titolo</mat-label>
          <input matInput [(ngModel)]="dataModel.title" name="title" required>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Descrizione</mat-label>
          <textarea matInput [(ngModel)]="dataModel.description" name="description" rows="5" required></textarea>
        </mat-form-field>
        <div class="row justify-content-center my-3">
          <a mat-button color="warn" (click)="closeDialogue()" [disabled]="loading" class="mr-2">{{"generic.buttons.cancel-and-back" | translate}}</a>
          <button mat-raised-button color="primary" [disabled]="loading" type="submit">{{"generic.send" | translate}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
