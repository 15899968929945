import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  public configInfo: any;

  public emailTemplates: any = [];

  public generics = null;

  constructor(private backend: BackendService) { }

  /**
   * Restituisce le informazioni di tutti i file di configurazione dell'app
   */
  public getConfig(callbackSuccess: any = () => {}, callbackError: any = () => {}) {

    this.backend.get('configs').subscribe(
      (result: any) => {
        this.configInfo = result;

        //  setto i template email
        let templates = result.find(x=> x.key == 'email_templates');
        if (templates != null && typeof templates != 'undefined' && templates?.value) {
          this.emailTemplates = templates.value;
        }

        this.generics = result.find(x => x.key === 'generics')?.value;


        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }


  /** Recupera un template basato sul tipo */
  public getEmailTemplate(templateType: string)
  {
    if (this.emailTemplates && this.emailTemplates.hasOwnProperty(templateType)) {
      return this.emailTemplates[templateType];
    }

    return "";
  }

  /** Recupera le traduzioni in funzione dell'organizzazione. */
  public getTranslations(callbackSuccess: any = () => {
  }, callbackError: any = () => {
  }) {

    this.backend.get('translations').subscribe(
      (result: any) => {
        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }

}
