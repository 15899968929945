import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ModalAddInvitationPageComponent} from '../modal-add-invitation-page/modal-add-invitation-page.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-modal-select-process-role',
  templateUrl: './modal-select-process-role.component.html',
  styleUrls: ['./modal-select-process-role.component.scss']
})
export class ModalSelectProcessRoleComponent implements OnInit {
  form: FormGroup;
  processRoles: { value: string, label: string }[] = [
    {value: 'CONSULTED', label: 'Consulted'},
    {value: 'RESPONSIBLE', label: 'Responsible'},
    {value: 'ACCOUNTABLE', label: 'Accountable'},
    {value: 'INFORMED', label: 'Informed'},
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalSelectProcessRoleComponent>
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      processRole: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formValue = this.form.value.processRole;
      this.dialogRef.close(formValue);
    }
  }

  closeDialogue(): void {
    this.dialogRef.close();
  }
}

