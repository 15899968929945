<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'components.decision-table.show-decision-table.select-process-role' | translate }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <mat-card class="w-75 my-5 ml-auto mr-auto">
      <mat-card-content>


        <div class="form-group text-center my-4">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'generic.roles.process-role' | translate }}</mat-label>
            <mat-select formControlName="processRole">
              <mat-option *ngFor="let role of processRoles" [value]="role.value">
                {{ role.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="row justify-content-center my-3">
          <a mat-button color="warn" (click)="closeDialogue()"  class="mx-2">
            {{ 'generic.buttons.cancel-and-back' | translate }}
          </a>
          <button mat-raised-button color="primary" class="mx-2" [disabled]="form.invalid" type="submit">
            <span>{{ 'generic.send' | translate }}</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
