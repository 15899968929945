<div class="qrcode-container">
  <img [src]="qrCodeUrl" alt="QR Code" class="qr-image">
  <div class="row container pt-2 pb-2 mx-auto justify-content-center">
    <button mat-button color="primary" class="mb-2" (click)="copyLink()">
      {{ "generic.buttons.copy-link" | translate }}
    </button>
    <button mat-flat-button
            color="primary" (click)="downloadQRCode()">Download QR-Code
    </button>
  </div>
</div>
