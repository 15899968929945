import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {UserService} from "../../services/user.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../services/general/toast.service";

@Component({
  selector: 'app-qr-code',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css']
})
export class QrCodeComponent implements OnInit, OnChanges {
  @Input() join_address: string = '';
  @Input() organization_logo: string = "";
  qrData: string = '';
  qrCodeUrl: string = '';

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private translateService: TranslateService,
    private toast: ToastService,
  ) { }

  ngOnInit() {
    this.updateQrCode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['join_address'] && changes['join_address'].currentValue !== changes['join_address'].previousValue) {
      this.updateQrCode();
    }
  }

  updateQrCode() {
    this.qrData = this.join_address;
    if (this.qrData) {
      this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(this.qrData)}`;
    }
  }

  downloadQRCode() {
    if (this.qrCodeUrl) {
      this.http.get(this.qrCodeUrl, { responseType: 'blob' }).subscribe((blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'qrcode.png';
        link.click();
      });
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.join_address);
    this.toast.success(this.translateService.instant('components.contests.contest-link'), this.translateService.instant('generic.link-copied'));
  }
}
