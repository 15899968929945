<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ data.title | translate }}</span>
    <span class="ml-auto"></span>
    <button mat-icon-button (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container">
  <form [formGroup]="addTextForm" (ngSubmit)="onSubmit()" novalidate>
    <mat-card class="w-75 my-5 ml-auto mr-auto">
      <mat-card-content>

        <div class="my-2">
          <app-rich-text-editor
            class="full-width"
            formControlName="value"
            required
            placeholder="">
          </app-rich-text-editor>
        </div>

        <div class="row justify-content-center my-3">
          <a mat-button color="warn" (click)="onCancel()">            {{ 'generic.buttons.cancel-and-back' | translate }}</a>
          <button mat-raised-button color="primary" type="submit" [disabled]="addTextForm.invalid">
            {{ 'generic.send' | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
