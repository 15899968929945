import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastService} from 'src/app/services/general/toast.service';
import {UserService} from 'src/app/services/user.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {FormControl, NgForm} from '@angular/forms';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {FrameworkService} from 'src/app/services/framework.service';
import {DecisionService} from 'src/app/services/decision.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {TranslateService} from '@ngx-translate/core';
import {OrganizationService} from '../../../../services/organization.service';
import {
  ModalImportOrganizationChartsComponent
} from '../../../../modals/modal-import-organization-charts/modal-import-organization-charts.component';

@Component({
  selector: 'app-new-decision-table-compile-proposal',
  templateUrl: './new-decision-table-compile-proposal.component.html',
  styleUrls: ['./new-decision-table-compile-proposal.component.scss'],
})
export class NewDecisionTableCompileProposalComponent implements OnInit {

  constructor(private toast: ToastService, private userService: UserService, private unitsService: UnitsService, private criteriaService: CriteriaService,
              private processRolesService: ProcessRolesService, private frameworkService: FrameworkService, private decisionService: DecisionService,
              private router: Router, public dialog: MatDialog, private translateService: TranslateService, private organizationService: OrganizationService) {
  }


  /**
   * Valida gli obiettivi inseriti
   */
  get primaryTargetsValid() {
    if (!this.model.primary_target.measure_unit) {
      return false;
    }
    return true;
  }


  /**
   * Valida gli obiettivi secondari inseriti
   */
  get secondaryTargetsValid() {
    for (const secondary_target of this.model.secondary_targets) {
      if (!secondary_target.title || !secondary_target.evaluation_criteria) {
        return false;
      }
    }
    return true;
  }


  /** Valida le proposte create */
  get proposalsValid() {

    for (const proposal of this.model.proposals) {
      if (!proposal.title) {
        return false;
      }

      for (const proposalCriteria of proposal.criteria) {
        if (this.criteriaService.criteriaIsMultipleChoice(proposalCriteria.typology)) {

          const findOptionTitleEmpty = proposalCriteria.options.filter(x => x.title == '');
          if (findOptionTitleEmpty.length > 0) {
            return false;
          }

          //  rendo valido il valore del criterio
          proposalCriteria.value = 'MULTIPLECHOICE';
        } else {
          if (!proposalCriteria.value) {
            return false;
          }
        }
      }
    }


    return true;
  }


  /** Valida le scale di valutazione */
  get ratingScaleValid() {

    if (this.change_rating_scale == false) {
      return true;
    }

    for (const ratingScale of this.model.rating_scales) {
      if (!ratingScale.description || !ratingScale.max_value || !ratingScale.min_value || !ratingScale.step) {
        return false;
      }
    }

    return true;
  }


  /** Valida le scale in base a se ne esiste almeno una selezionata */
  get ratingScaleSelectionValid() {

    if (this.change_rating_scale == false) {
      return true;
    }

    for (const ratingScale of this.model.rating_scales) {
      if (ratingScale.selected == true) {
        return true;
      }
    }

    return false;
  }


  /** Valida il team inserito */
  get teamMembersValid() {

    for (const team_member of this.model.team_members) {
      if (!team_member.name || !team_member.email || !team_member.process_role) {
        return false;
      }
    }

    return true;
  }

  @Output() goBackEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() frameworkModel: any;
  @Input() problemStatementModel: any;
  @Input() project_id: any;
  frameworkSelected: any;
  loading = false;

  change_rating_scale = false;
  rating_scale_empty: any = {
    min_value: null,
    max_value: null,
    step: null,
    description: null,
    selected: false
  };
  suggestedTeamMemberUsers = [];
  timerEmailUpdate;
  selectedOrganizationChart: number;

  model: any = {
    primary_target: {},
    secondary_targets: [],
    proposals: [],
    rating_scales: [],
    team_members: [],
  };


  organizationCharts = [];

  userorganization: any = [];
  professionalRoles: any = [];
  process_roles: any = [];
  currentOrganization: any = {};

  createArrayOfNumber(start: number = 0, end: number) {
    return [...Array(end).keys()];
  }

  ngOnInit(): void {
    this.init();
  }

  init() {

    //  inizializzo i ruoli nei processi decisionali
    this.process_roles = this.processRolesService.getAllProcessRoles();

    //  inizializzo i dati dell'utente
    this.professionalRoles = this.userService.getAllProfessionalRolesForCurrentOrganization();

    if (this.frameworkModel?.framework_id) {

      this.loading = true;
      this.frameworkService.get(this.frameworkModel.framework_id, (response) => {

        this.frameworkSelected = JSON.parse(JSON.stringify(response));
        this.prepareTargets();
        this.prepareProposal();
        this.prepareRatingScales();
        this.prepareTeam();
        this.prepareAttachment();

        this.userorganization = this.userService.getAllOrganizations();
        this.currentOrganization = this.userorganization.find(x => x.id == this.frameworkSelected.organization_id);

        this.organizationService.allOrganizationCharts(this.userService.currentOrganization.id, (result) => {
          this.organizationCharts = result;
        });

        this.loading = false;

        //  ENEL: se l'organizzazione è enel allora salvo direttamente il form
        if (this.userService.currentOrganization.customs?.profile) {
          if (['alexion', 'enel'].includes(this.userService.currentOrganization.customs.profile)) {
            this.onSubmit(null);
          }
        }


      }, () => {
        this.loading = false;
      });
    }
  }


  importOrganizationChart() {

    const selectedOrganizationChartModel = this.model.organizationCharts.find((x) => x.id = this.selectedOrganizationChart);

    selectedOrganizationChartModel.organization_users.map((organizationChart) => {
      this.model.team_members = [];
      this.organizationService.getOrganizationUser(selectedOrganizationChartModel.organization_id, organizationChart.user_id, (result) => {
        this.model.team_members.push({name: result.user.name, email: result.user.email, process_role: 'CONSULTED'});
      });

    });

  }

  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit(form: NgForm) {
    //  validazione dei dati
    if (!this.primaryTargetsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.primary-error'));
      return;
    }
    if (!this.secondaryTargetsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.secondary-error'));
      return;
    }
    if (!this.proposalsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.third-error'));
      return;
    }

    // Modifica per IPSEN
    // if (!this.teamMembersValid && this.currentOrganization?.customs?.profile != 'ipsen') {
    //   this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.fourth-error'));
    //   return;
    // } else {
    //   this.model.team_members = [];
    // }

    if (form != null && !form.form.valid) {
      return;
    }

    this.frameworkModel.organization_project_id = parseInt(this.project_id);
    // console.log(this.frameworkModel);
    // console.log(this.model);
    // console.log(this.problemStatementModel);


    this.loading = true;
    this.decisionService.createDecisionTable({
      decision: this.frameworkModel,
      proposal: this.model,
      problem_statement: this.problemStatementModel
    }, (result) => {
      if (this.currentOrganization?.decision_tables_count) {
        this.currentOrganization.decision_tables_count++;
      }
      this.toast.success('New decision', this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.success-decision-table-created'));
      this.router.navigate(['decision', result.id, 'show']);
      this.loading = false;

    }, () => {
      this.loading = false;
    });
  }


  /**
   * Prepara gli obiettivi sulla base del framework selezionato
   */
  prepareTargets() {

    this.model.primary_target = {};
    this.model.secondary_targets = [];

    //  Primary Target
    this.model.primary_target = {
      type: this.frameworkSelected.primary_target.type,
      title: this.frameworkSelected.primary_target.title,
      measure_unit: null,
      date: null,
      expected_value: null,
      evaluation_criteria: this.frameworkSelected.primary_target.evaluation_criteria,
      framework_id: this.frameworkSelected.id
    };

    //  ENEL: controllo che l'organizzazione sia enel e gli setto automaticamente l'obiettivo principale
    if (this.userService.currentOrganization.customs?.profile) {
      if (this.userService.currentOrganization.customs.profile == 'enel') {
        this.model.primary_target.measure_unit = 'PERCENTAGE';
        this.model.primary_target.expected_value = 120;
      }
      if (this.userService.currentOrganization.customs.profile == 'alexion') {
        this.model.primary_target.measure_unit = 'POINT';
        this.model.primary_target.expected_value = 100;
      }
    }

    //  Secondary Target
    this.frameworkSelected.secondary_targets.forEach(target => {
      const compiledTarget = {
        type: target.type,
        title: target.title,
        measure_unit: null,
        date: null,
        expected_value: null,
        evaluation_criteria: target.evaluation_criteria,
        framework_id: this.frameworkSelected.id
      };

      //  ENEL: controllo che l'organizzazione sia enel e gli setto automaticamente gli obiettivi
      if (this.userService.currentOrganization.customs?.profile) {
        if (this.userService.currentOrganization.customs.profile == 'enel') {
          compiledTarget.measure_unit = 'PERCENTAGE';
          compiledTarget.expected_value = 120;
        }
        if (this.userService.currentOrganization.customs.profile == 'alexion') {
          compiledTarget.measure_unit = 'POINT';
          compiledTarget.expected_value = 100;
        }

      }

      this.model.secondary_targets.push(compiledTarget);
    });
  }


  /**
   * Setta una proposta sulla base del framework selezionato
   */
  prepareProposal() {
    this.model.proposals = [];
    this.addNewProposal();
  }


  /** Aggiunge una proposal vuota */
  addNewProposal() {

    const newProposal = {title: null, criteria: []};

    //  ENEL: se l'organizzazione è enel preparo il nomde della proposta come richiesto
    if (this.userService.currentOrganization.customs?.profile) {
      const organization = this.userService.currentOrganization.customs.profile;
      if (organization === 'enel') {
        newProposal.title = this.frameworkSelected.title;
      }
      if (organization === 'alexion') {
        newProposal.title = this.frameworkModel.decision_name + ' S_1';
      }
    }

    for (const criterio of this.frameworkSelected.criteria) {
      criterio.options;
      const compiledCriterio = {
        title: criterio.title,
        typology: criterio.typology,
        measure_unit: criterio.measure_unit,
        options: criterio.options,
        value: null,
        framework_criteria_id: criterio.id,
        additional: criterio.additional,
        attachment: []
      };
      newProposal.criteria.push(compiledCriterio);
    }


    this.model.proposals.push(newProposal);
  }

  setUserInfoByEmail(event, team_member) {
    const userFinded = this.suggestedTeamMemberUsers.find((user) => event.option.value.email === user.email);
    const full_name = userFinded?.details.first_name + ' ' + userFinded?.details.last_name;
    team_member.name = full_name || '';
    team_member.email = userFinded.email;

    this.suggestedTeamMemberUsers = [];
  }


  displayFn(option: any): string {
    return option.email || '';
  }

  onClickSearchUser(teamMember: any) {
    let email = '';
    if (teamMember?.email !== null && teamMember.email.length > 0) {
      email = teamMember?.email;
    }
    this.organizationService.getOrganizationUsersByEmail(
      email,
      this.userService.currentOrganization.id,
      (result) => {
        this.suggestedTeamMemberUsers = result.map(result => result.user);
      },
    );
  }


  onInputSearchUserChange(value: any, team_member) {
    team_member.email = value;
    if (this.timerEmailUpdate == null) {

      this.timerEmailUpdate = setTimeout(() => {
        this.timerEmailUpdate = null;
        if (value == '') {
          this.suggestedTeamMemberUsers = [];
        } else {
          this.organizationService.getOrganizationUsersByEmail(
            value,
            this.userService.currentOrganization.id,
            (result) => {
              this.suggestedTeamMemberUsers = result.map(result => result.user);
            },
          );
        }
      }, 800);
    }
  }

  /** Rimuove una proposal */
  removeNewProposal(proposal_index) {
    this.model.proposals.splice(proposal_index, 1);
  }

  /**
   * Setto gli allegati nei criteri
   */
  prepareAttachment() {

    this.model.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        criterio.attachment = [];
        if (criterio.attachment_pubmed) {
          criterio.attachment_pubmed.forEach(pubmed => {
            criterio.attachment.push(pubmed);
          });
        }
        if (criterio.attachment_files) {
          criterio.attachment_files.forEach(file => {
            criterio.attachment.push(file);
          });
        }
      });
    });
  }


  /** Prepara le scale di valutazione per questo processo decisionale */
  prepareRatingScales() {
    this.model.rating_scales = [];
    this.addNewRatingScale();
  }


  /** Aggiunge una scala di valutazione vuota */
  addNewRatingScale() {
    this.model.rating_scales.push(JSON.parse(JSON.stringify(this.rating_scale_empty)));
  }


  /** Setta un team member vuoto iniziale */
  prepareTeam() {
    this.model.team_members = [];
    this.addNewTeamMember();
  }

  /** Aggiunge un team member vuoto */
  addNewTeamMember() {

    this.model.team_members.push({
      name: null,
      email: null,
      organization_professional_role_id: null,
      process_role: null,
      complete_process_enabled: true,
      review_enabled: true,
      provide_new_proposal_enabled: true
    });

    //  ENEL: controllo che l'organizzazione sia enel e gli setto automaticamente il primo membro del team che deve essere consulted
    if (this.userService.currentOrganization.customs?.profile && ['enel', 'alexion'].includes(this.userService.currentOrganization.customs.profile)) {
      this.model.team_members[0].name = this.userService.userInfo.name;
      this.model.team_members[0].email = this.userService.userInfo.email;
      this.model.team_members[0].process_role = 'CONSULTED';
    } else {
      // Aggiunto per la modifica per Ipsen. I team members vengono aggiunti sul tavolo con gli inviti.
      this.model.team_members = [];
    }

  }

  /** Rimuove un determinato utente inserito */
  removeTeamMember(positionToDelete) {

    this.model.team_members.splice(positionToDelete, 1);
    if (this.model.team_members.length == 0) {
      this.addNewTeamMember();
    }
  }


  /**
   * Aggiunta di un nuovo
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(proposal_index: any, criterio: any, attachment_type: string) {

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        proposal_index,
        criterio,
        uploadUrl: 'upload/tmp-proposal-file'
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData) {

        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }

  openChartsModal() {

    const dialogRef = this.dialog.open(ModalImportOrganizationChartsComponent, {
      panelClass: 'medium-dialog',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.model.team_members = this.model.team_members.filter((team_member) => {
          return team_member.name !== null && team_member.email !== null;
        });
        result.forEach((org_usr) => {
          const team_member = this.model.team_members.find((team_member) => team_member.email === org_usr?.user?.email);
          if (team_member) {
            team_member.imported_by_organization_chart_id = org_usr.imported_by_organization_chart_id;
          } else {
            this.model.team_members.push({
              name: org_usr?.user?.name,
              email: org_usr?.user?.email,
              organization_professional_role_id: null,
              imported_by_organization_chart_id: org_usr.imported_by_organization_chart_id,
              process_role: null,
              complete_process_enabled: true,
              review_enabled: true,
              provide_new_proposal_enabled: true
            });
          }
        });
      }
    });
  }

  getOrganizationChart(id) {
    return this.organizationCharts.find((org_chart) => {
      return id === org_chart.id;
    });
  }


  /**
   * Rimozione di un allegato
   * @param attachIndex
   * @param criterio
   */
  onClickRemoveEvidence(attachIndex, criterio) {

    const attachment = criterio.attachment[attachIndex];

    switch (attachment.type) {
      case 'PUBMED':
        const pubmedIndex = criterio.attachment_pubmed.findIndex(x => x.PMID == attachment.PMID);
        if (pubmedIndex >= 0) {
          criterio.attachment_pubmed.splice(pubmedIndex, 1);
        }
        break;
      case 'FILE':
        const fileIndex = criterio.attachment_files.findIndex(x => x.id == attachment.id);
        if (fileIndex >= 0) {
          criterio.attachment_files.splice(fileIndex, 1);
        }
        break;
    }

    this.prepareAttachment();
  }


  //  restituisce la stringa corretta in base al tipo di criterio
  getPlaceholderString(criteria_typology) {

    if (this.criteriaService.criteriaIsQuantitative(criteria_typology)) {
      return this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.placeholders.insert-numeric-value');
    }

    return this.translateService.instant('components.decision-table.new-decision-table.compile-proposal.placeholders.insert-description');
  }


  /**
   * Setto un allegato pubmed
   * @param attachmentPubmed
   */
  addPubmedAttachment(attachmentPubmed) {

    for (const proposalCriteria of this.model.proposals[attachmentPubmed.proposal_index].criteria) {
      if (proposalCriteria.framework_criteria_id == attachmentPubmed.criterio.framework_criteria_id) {
        if (!proposalCriteria.attachment_pubmed) {
          proposalCriteria.attachment_pubmed = [];
        }

        proposalCriteria.attachment_pubmed.push({
          type: 'PUBMED',
          PMID: attachmentPubmed.attachmentObject.PMID,
          title: attachmentPubmed.attachmentObject.ArticleTitle,
          link: attachmentPubmed.attachmentObject.link
        });

        break;
      }
    }


    this.prepareAttachment();
  }


  /**
   * Serto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {

    for (const fileAttached of attachmentFile.attachmentObject) {
      for (const proposalCriteria of this.model.proposals[attachmentFile.proposal_index].criteria) {
        if (proposalCriteria.framework_criteria_id == attachmentFile.criterio.framework_criteria_id) {
          if (!proposalCriteria.attachment_files) {
            proposalCriteria.attachment_files = [];
          }

          proposalCriteria.attachment_files.push({
            id: fileAttached.id,
            type: 'FILE',
            ext: fileAttached.ext,
            size: fileAttached.size,
            title: fileAttached.title,
            path: fileAttached.path,
            link: fileAttached.link
          });

          break;
        }
      }

    }

    this.prepareAttachment();
  }


  backButtonOnClick(event) {
    event.preventDefault();
    this.goBackEvent.emit(true);
  }


  public getUnitsService() {
    return this.unitsService;
  }

  public getCriteriaService() {
    return this.criteriaService;
  }

  public getProcessRolesService() {
    return this.processRolesService;
  }


}
