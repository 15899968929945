<div #weightsUpdate>

  <div class="row" *ngIf="loading">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>

  <div class="weight-counter">
    <svg class="weight-counter-circle" viewBox="0 0 100 100">
      <path class="circle-bg" d="M 50,50 m -40,0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0" stroke-width="3.8"/>
      <path class="circle" [attr.stroke-dasharray]="getDashArray()" d="M 50,50 m -40,0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"/>
      <text class="percentage" x="50" y="50">{{ getCriteriaWeightsSum }}%</text>
    </svg>
  </div>

  <form #f="ngForm">
    <div class="container mt-5" *ngIf="criteriaAndTargetsLoaded">
      <div class="container-fluid accordion-container px-0 px-md-3"
           *ngIf="isMultiobjectiveFramework && model?.secondary_targets?.length > 0 && model?.primary_target">
        <div class="container">
          <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="index">1</span>
                  {{ 'generic.objectives' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <mat-accordion class="accordion bg-white col-12" multi [togglePosition]="'before'">
                  <mat-expansion-panel class="px-0 bg-white" expanded>
                    <mat-expansion-panel-header class="bg-white">
                      <mat-panel-title>
                        <span>{{ model?.primary_target?.title }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill"
                                    *ngIf="model?.primary_target?.date">
                      <mat-label>{{ 'components.responses.reach-day' | translate }}</mat-label>
                      <input matInput name="primary_target_date_{{model?.primary_target?.id}}"
                             [ngModel]="(model?.primary_target?.date| formatdate | date: 'shortDate')"
                             placeholder="{{'generic.date' | translate}}" readonly>
                    </mat-form-field>

                    <mat-form-field class="input-primary col-5 col-lg-6" *ngIf="model?.primary_target?.measure_unit">
                      <input matInput name="primary_target_unit_{{model?.primary_target?.id}}"
                             placeholder="{{'generic.unit' | translate}}"
                             [ngModel]="getUnitsService().getUnitValueByKey(model?.primary_target?.measure_unit)"
                             readonly>
                    </mat-form-field>

                    <mat-form-field class="input-primary col-12 col-lg-6 mt-2">
                      <input matInput name="primary_target_unit_{{model?.primary_target?.id}}"
                             placeholder="{{'components.responses.target-value' | translate}}"
                             [ngModel]="getCriteriaService().getCriteriaValueByKey(model?.primary_target?.evaluation_criteria)"
                             readonly>
                    </mat-form-field>


                    <ng-container *ngFor="let target of model.secondary_targets; let i = index">

                      <div class="col-12 col-lg-6 px-0 mb-0 mt-3" *ngIf="i < 1">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0 mt-4">{{ 'generic.secondary' | translate }}</mat-label>
                        </div>
                      </div>

                      <div
                        class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                        <div class="container">
                          <mat-accordion class="accordion" multi [togglePosition]="'before'">
                            <mat-expansion-panel class="px-0" expanded>
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  <span>{{ target.title }}</span>
                                  <i class="icon-circle_info d-flex"></i>
                                </mat-panel-title>
                              </mat-expansion-panel-header>

                              <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill"
                                              *ngIf="target.date">
                                <mat-label>{{ 'components.responses.reach-day' | translate }}</mat-label>
                                <input matInput name="secondary_target_date_{{target?.id}}"
                                       [ngModel]="(target.date| formatdate | date: 'shortDate')"
                                       placeholder="{{'generic.date' | translate}}" readonly>
                              </mat-form-field>

                              <mat-form-field class="input-primary col-5 col-lg-6" *ngIf="target.measure_unit">
                                <input matInput name="secondary_target_unit_{{target?.id}}"
                                       placeholder="{{'generic.unit' | translate}}"
                                       [ngModel]="getUnitsService().getUnitValueByKey(target.measure_unit)" readonly>
                              </mat-form-field>

                              <mat-form-field class="input-primary col-12 col-lg-6 mt-2">
                                <input matInput name="secondary_target_value_{{target?.id}}"
                                       placeholder="{{'components.responses.target-value' | translate}}"
                                       [ngModel]="getCriteriaService().getCriteriaValueByKey(target.evaluation_criteria)"
                                       readonly>
                              </mat-form-field>

                              <div class="row border-top-grey pt-4 my-3">
                                <small class="col-12">{{ 'generic.evaluation' | translate }}</small>
                                <div class="col-12">{{ target.title }}</div>
                                <!-- <mat-slider class="w-100 mt-2 cdk-focused" min="0" max="100" step="1" [(ngModel)]="secondaryTarget.evaluation.weight" color="primary"></mat-slider> -->
                                <mat-slider [disabled]="!editMode" name="secondary_target_weight_{{target?.id}}"
                                            class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1"
                                            [thumbLabel]="true" [(ngModel)]="target.evaluation.weight"
                                            color="primary"></mat-slider>
                                <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                  <small
                                    class="float-left">{{ 'components.responses.low-importance' | translate }}</small>
                                  <small
                                    class="float-right">{{ 'components.responses.high-importance' | translate }}</small>
                                </div>
                              </div>

                            </mat-expansion-panel>
                          </mat-accordion>
                        </div>
                      </div>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div class="container-fluid accordion-container px-0 px-md-3 mt-5">
        <div class="container">
          <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
              <span class="index">
                <ng-container *ngIf="isMultiobjectiveFramework">2</ng-container>
                <ng-container *ngIf="!isMultiobjectiveFramework">1</ng-container>
              </span>
                  {{ 'generic.criteria' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngIf="isMultiobjectiveFramework">
                <ng-container *ngFor="let target of model.secondary_targets; let i = index">
                  <div class="col-12 col-lg-6 px-0 mb-0 mt-5">
                    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                      <mat-label class="box-title px-3 mb-0"
                                 *ngIf="i == 0">{{ 'components.responses.multiobjectives.criteria-to-secondary' | translate }}
                      </mat-label>
                    </div>
                  </div>

                  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                    <div class="container">
                      <mat-accordion class="accordion" multi [togglePosition]="'before'">
                        <mat-expansion-panel class="px-0" expanded>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <span>{{ target?.title }}</span>
                              <i class="icon-circle_info d-flex"></i>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-container *ngFor="let criteria of target?.criteria">
                            <div class="row border-bottom-grey pt-0 pb-4 my-3">
                              <small class="col-12">{{ 'generic.evaluation' | translate }}</small>
                              <div class="col-12">{{ criteria.title }}</div>
                              <mat-slider [disabled]="!editMode"
                                          name="secondary_criteria_weight_{{criteria?.id}}__{{target?.id}}"
                                          class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1"
                                          [thumbLabel]="true" [(ngModel)]="criteria.evaluation.weight"
                                          color="primary"></mat-slider>
                              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                <small
                                  class="float-left">{{ 'components.responses.low-importance' | translate }}</small>
                                <small
                                  class="float-right">{{ 'components.responses.high-importance' | translate }}</small>
                              </div>
                            </div>
                          </ng-container>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row" *ngIf="isMulticriteria">
                <div class="col-12 col-lg-6 px-0 mb-0 mt-5">
                  <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                    <mat-label
                      class="box-title px-3 mb-0">{{ 'components.responses.multicriteria.criteria-to-primary' | translate }}
                    </mat-label>
                  </div>
                </div>

                <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                  <div class="container">
                    <mat-accordion class="accordion" multi [togglePosition]="'before'">
                      <mat-expansion-panel class="px-0" expanded>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <span>{{ model?.primary_target?.title }}</span>
                            <i class="icon-circle_info d-flex"></i>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngFor="let criteria of model?.primary_target?.criteria">
                          <div class="row border-bottom-grey pt-0 pb-4 my-3">
                            <small class="col-12">{{ 'generic.evaluation' | translate }}</small>
                            <div class="col-12">{{ criteria.title }}</div>
                            <mat-slider [disabled]="!editMode" name="secondary_criteria_weight_{{criteria?.id}}"
                                        class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1"
                                        [thumbLabel]="true" [(ngModel)]="criteria.evaluation.weight"
                                        color="primary"></mat-slider>
                            <div class="d-flex justify-content-between w-100 mb-2 px-2">
                              <small class="float-left">{{ 'components.responses.low-importance' | translate }}</small>
                              <small
                                class="float-right">{{ 'components.responses.high-importance' | translate }}</small>
                            </div>
                          </div>
                        </ng-container>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </form>

  <div class="buttons-container container py-4" *ngIf="criteriaAndTargetsLoaded && !framework_model">
    <button
      class="button-primary float-left"
      mat-button
      [disabled]="loading"
      (click)="close.emit()"
    >
      <i class="icon-Backward-arrow-small"></i>
      {{ "generic.buttons.close" | translate }}
    </button>
    <button
      *ngIf="editMode"
      class="button-primary float-right"
      mat-button
      (click)="submit()"
      [disabled]="loading"
    >
      <i class="icon-Forward-arrow-small"></i>
      {{ "generic.buttons.save-and-close" | translate }}
    </button>
  </div>
</div>
