import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {UserService} from 'src/app/services/user.service';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {WorkingSectorsService} from 'src/app/services/config/workingSectors.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {FrameworkService} from 'src/app/services/framework.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitleService} from 'src/app/services/general/header-title.service';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalUploadFrameworkComponent} from 'src/app/modals/modal-upload-framework/modal-upload-framework.component';

@Component({
  selector: 'app-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss']
})
export class FrameworkComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  id: any;

  //  modello del framework
  model: any = {
    title: null,
    organization_id: null,
    analysis_type: null,
    public: false,
    hidden: false,
    sectors: [],
    purpose_description: '',
    primary_target: {type: 'primary', title: '', evaluation_criteria: null},
    secondary_targets: [],
    criteria: [],
    constraints: [],
    options_role: false,
    options_role_required: false,
    roles: []
  };

  weights_is_setted = false;
  weights_mandatories = null;

  process_roles: any = [];
  analysisMethods: any = [];
  userorganization: any = [];
  professionalRoles: any = [];
  currentOrganization: any = {};

  constructor(private toast: ToastService, private unitsService: UnitsService, private criteriaService: CriteriaService,
              private workingSectorService: WorkingSectorsService, private analysisMethodsService: AnalysisMethodsService,
              public userService: UserService, private frameworkService: FrameworkService,
              private activeRoute: ActivatedRoute, private router: Router, private headerTitleService: HeaderTitleService,
              private processRolesService: ProcessRolesService, private translateService: TranslateService,
              public dialog: MatDialog,
              private analysisService: AnalysisMethodsService,
              private cdr: ChangeDetectorRef) {
  }

  settedWeights: any = null;


  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_framework;
      if (this.id) {
        this.frameworkService.get(this.id, (result) => {
          this.model = result;
          this.init();
        }, () => {
          this.router.navigate(['/framework']);
          this.loading = false;
        });
      } else {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

  init() {

    //  inizializzo i metodi disponibili
    this.analysisMethods = this.analysisMethodsService.getAllAnalysisMethods().map(v => ({...v, enabled: false}));

    //  inizializzo i ruoli nei processi decisionali
    this.process_roles = this.processRolesService.getAllProcessRoles();

    //  inizializzo i dati dell'utente
    this.userorganization = this.userService.getAllOrganizations();

    //  recupero i dati dei ruoli professionali dell'organizzazione corrente
    this.professionalRoles = this.userService.getAllProfessionalRolesForCurrentOrganization();

    //  all'avvio inserisco un settore vuoto
    if (this.model.sectors.length == 0) {
      this.addSector();
    }

    //  all'avvio inserisco un criterio vuoto
    if (this.model.criteria.length == 0) {
      this.addCriteria();
    }

    //  all'avvio inserisco un ruolo vuoto
    // if (this.model.roles.length == 0)
    //   this.addRole();

    //  pulisco i ceriteri se sono arrivati senza valori di informazioni aggiuntive
    this.model.criteria.forEach(criterio => {
      if (!criterio.additional) {
        criterio.additional = {};
      }
    });

    //  setto il metodo di analisi se presente
    if (this.model.analysis_type) {
      const methodIndex = this.analysisMethods.findIndex(x => x.type == this.model.analysis_type);
      if (methodIndex >= 0) {
        this.analysisMethods[methodIndex].enabled = true;
      }
    }

    //  setto le opzioni per i ruoli del team
    this.model.options_role = this.model.options_role != 0;
    this.model.options_role_required = this.model.options_role_required != 0;

    //  costruisco l'array dei vincoli
    this.model.constraints.forEach(constraint => {
      const criteria1 = this.model.criteria.find(x => x.id == constraint.framework_criteria_1_id);
      const criteria2 = this.model.criteria.find(x => x.id == constraint.framework_criteria_2_id);
      constraint.criteria_1_order = criteria1?.order;
      constraint.criteria_2_order = criteria2?.order;
    });

    //  setto l'organizzazione se presente
    if (this.model.organization_id) {
      this.selectOrganizationOnChange();
    }

    //  setto il titolo della pagina
    if (this.model.id) {
      this.headerTitleService.setSubtitle(this.model.title);
    }

    this.currentOrganization = this.userService.getCurrentOrganization();
  }

  get criteriaTargetsWeightsEnabled() {
    if (this.model?.criteria?.length > 0 &&
      this.model?.criteria[0].typology &&
      this.model?.primary_target?.evaluation_criteria &&
      this.model?.analysis_type !== null &&
      !this.getAnalysisService().isConsensusByType(this.model?.analysis_type)) {

      if (this.model?.secondary_targets?.length === 0 && this.getAnalysisService().isMulticriteriaByType(this.model?.analysis_type)) {
        return true;
      }

      if (this.model?.secondary_targets?.length > 1 && this.getAnalysisService().isMultiobjectiveByType(this.model?.analysis_type)) {
        return true;
      }
    }

    return false;
  }


  /**
   * Gestione della selezione dei metodi di analisi
   */
  disableAnalysis() {
    this.analysisMethods.forEach(element => {
      element.enabled = false;
    });
  }

  public getAnalysisService() {
    return this.analysisService;
  }

  get stringifyModel() {
    return JSON.stringify(this.model);
  }

  analysisOnClick(methodIndex: number) {
    this.disableAnalysis();
    this.analysisMethods[methodIndex].enabled = !this.analysisMethods[methodIndex].enabled;
    this.model.analysis_type = this.analysisMethods[methodIndex].type;
  }

  /** Evento di cambio dell'organizzazione della select */
  selectOrganizationOnChange() {
    this.currentOrganization = this.userorganization.find(x => x.id == this.model.organization_id);
  }

  /**
   * Gestione dei box dei settori
   */
  getSubsectorsArrayFiltered(sectorKey) {
    return this.workingSectorService.getAllsubsectorsForSelectBySectorKey(sectorKey);
  }

  addSector() {
    this.model.sectors.push({sector_key: null, subsector_key: null});
  }

  removeSector(positionToDelete) {
    this.model.sectors.splice(positionToDelete, 1);
    if (this.model.sectors.length == 0) {
      this.addSector();
    }
  }


  /**
   * Gestione dei box degli obiettivi
   */
  addSecondaryTarget() {
    this.model.secondary_targets.push({type: 'secondary', title: '', evaluation_criteria: null});
  }

  removeSecondaryTarget(positionToDelete) {
    this.model.secondary_targets.splice(positionToDelete, 1);
  }


  /**
   * Gestione dei box dei criteri
   */
  onChangeCriteriaTypology(criteriaIndex) {

    //  se sto creando un criterio multiscelta e ancora non ho nessuna opzione inserita allora lo faccio
    if (this.criteriaService.criteriaIsMultipleChoice(this.model.criteria[criteriaIndex].typology)
      && !this.model.criteria[criteriaIndex].options) {
      this.addCriteriaOption(criteriaIndex);
    }
  }

  addCriteria() {
    this.model.criteria.push({
      order: this.model.criteria.length + 1,
      title: '',
      measure_unit: null,
      evaluation: null,
      typology: null,
      additional: {}
    });
  }

  weightsUpdated(value) {
    this.settedWeights = {...value};
    this.cdr.detectChanges();
  }

  removeCriteria(positionToDelete) {
    this.model.criteria.splice(positionToDelete, 1);
    if (this.model.criteria.length == 0) {
      this.addCriteria();
    }
  }

  addCriteriaOption(criteriaIndex) {
    if (!this.model.criteria[criteriaIndex].options) {
      this.model.criteria[criteriaIndex].options = [];
    }

    this.model.criteria[criteriaIndex].options.push({title: ''});
  }

  removeCriteriaOption(criteriaIndex, positionToDelete) {
    this.model.criteria[criteriaIndex].options.splice(positionToDelete, 1);
    if (this.model.criteria[criteriaIndex].options.length == 0) {
      this.addCriteriaOption(criteriaIndex);
    }
  }

  /**
   * Gestione dei box dei criteri
   */
  addConstraint() {
    this.model.constraints.push({description: '', from: null, to: null});
  }

  removeConstraint(positionToDelete) {
    this.model.constraints.splice(positionToDelete, 1);
  }


  /**
   * Gestione della selezione dei bottoni di opzione dei ruoli
   */
  optionRoleOnClick(event) {
    this.model.options_role = event;

    if (this.model.options_role == true) {
      this.addRole();
    } else {
      this.model.roles = [];
    }
  }


  /**
   * Gestione dell'obbligatorietà dei bottoni dei ruoli
   */
  optionRoleRequiredOnClick(event) {
    this.model.options_role_required = event;
  }


  /**
   * Gestione dei box dei ruoli
   */
  addRole() {
    this.model.roles.push({
      organization_professional_role_id: null, process_role: null,
      complete_process_enabled: true, review_enabled: true, provide_new_proposal_enabled: true
    });
  }

  removeRole(positionToDelete) {
    this.model.roles.splice(positionToDelete, 1);
    if (this.model.roles.length == 0) {
      this.addRole();
    }
  }


  /**
   * Aggiunta di un nuovo allegato
   * @param criterio
   * @param attachment_type
   */
  onClickUploadFile() {

    const dialogRef = this.dialog.open(ModalUploadFrameworkComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-upload-framework',
        uploadUrl: 'upload/generate-framework',
        type: ['xls', 'xlsx', 'csv']
      }
    });

    //  se ho creato correttamente il framework allora lo mostra
    dialogRef.afterClosed().subscribe(uploadedData => {
      if (uploadedData && uploadedData.attachmentObject && uploadedData.attachmentObject.length > 0 && uploadedData.attachmentObject[0].id) {

        //  porto l'utente alla home
        // this.router.navigate(['framework', uploadedData.attachmentObject[0].id]);
        this.toast.success(this.translateService.instant('generic.decision-schema'), this.translateService.instant('pages.frameworks.framework.success-decision-scheme'));
        this.router.navigate(['/home']);
      }
    });
  }


  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit() {

    //  validazione dei dati

    this.model.organization_id = this.currentOrganization.id;

    if (!this.model.title) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-decision-schema'));
      return;
    }
    if (!this.model.organization_id) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-organization'));
      return;
    }
    if (!this.model.analysis_type) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-methodology'));
      return;
    }
    if (!this.sectorsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-sector'));
      return;
    }
    if (!this.model.purpose_description) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-description'));
      return;
    }
    if (!this.primaryTargetsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-primary-obj'));
      return;
    }
    if (!this.secondaryTargetsValid) {
      return;
    }
    if (!this.criteriaValid) {
      return;
    }
    if (!this.constraintsValid) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-constraints'));
      return;
    }
    if (!this.question_role_satisfied) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-question-team'));
      return;
    }
    if (this.model.options_role == true && !this.role_satisfied) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-role-team'));
      return;
    }

    const model = {
      ...this.model,
      settedWeights: this.weights_is_setted ? this.settedWeights : undefined,
      weights_mandatories: !!this.weights_mandatories
    };

    if (this.weights_is_setted && this.getCriteriaWeightsSum(model.settedWeights) !== 100){
      this.toast.error(this.translateService.instant('generic.error'), this.translateService.instant('modals.modal-edit-weights.weights-total-must-be-100'));
      return;
    }

    this.loading = true;
    this.frameworkService.save(model, (result) => {
      this.loading = false;
      this.toast.success(this.translateService.instant('generic.decision-schema'), this.translateService.instant('pages.frameworks.framework.success-decision-scheme'));
      this.router.navigate(['./all'], {relativeTo: this.activeRoute});
    }, (error) => {
      this.loading = false;
    });

  }

  getCriteriaWeightsSum(model) {
    return model.primary_target.criteria
        .map((criteria: any) => criteria.evaluation?.weight || 0)
        .reduce((acc: number, weight: number) => acc + weight, 0);
  }


  /**
   * Evento di submit che invia solo l'aggiornamento dei dati del framework
   * i dati che è possibile aggiornare sono pochi e selezionai
   */
  onSubmitUpdate() {


    const modelUpdate = {
      hidden: this.model.hidden,
      public: this.model.public
    };

    this.loading = true;
    this.frameworkService.update(this.model.id, modelUpdate, (result) => {
      this.loading = false;
      this.toast.success(this.translateService.instant('generic.decision-schema'), this.translateService.instant('pages.frameworks.framework.updated-decision-scheme'));
    }, (error) => {
      this.loading = false;
    });

  }


  /**
   *  Scarica o il framework o un esempio di CSV, XLS del framework
   */
  onClickDownloadExampleData() {
    this.frameworkService.donwloadData(this.id);
  }


  /**
   * Valida i settori
   */
  get sectorsValid() {
    if (this.model.sectors.length == 0) {
      return false;
    }
    for (const sector of this.model.sectors) {
      if (!sector.sector_key) {
        return false;
      }
    }
    return true;
  }


  /**
   * Valida gli obiettivi inseriti
   */
  get primaryTargetsValid() {
    if (!this.model.primary_target.evaluation_criteria || !this.model.primary_target.title) {
      return false;
    }
    return true;
  }

  /**
   * Valida gli obiettivi secondari inseriti
   */
  get secondaryTargetsValid() {
    let counter = 0;
    for (const secondary_target of this.model.secondary_targets) {
      if (!secondary_target.title || !secondary_target.evaluation_criteria) {
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-secondary-obj'));
        return false;
      }
      counter++;
    }

    //  Se è una multiobiettivo bisogna avere almeno due obiettivi secondari inseriti
    if (this.model.analysis_type == 5 && counter < 2) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-multitarget-secondary-obj'));
      return false;
    }

    //  Se è una multicriterio non devono esserci obiettivi secondari
    if (this.model.analysis_type == 1 && counter > 0) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-multicriteria-secondary-obj'));
      return false;
    }

    //  Nella consensus non devono esserci obiettivi secondari
    if (this.model.analysis_type == 2 && counter > 0) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-consent-analysis'));
      return false;
    }

    //  Nel Voting non devono esserci obiettivi secondari
    if (this.model.analysis_type == 3 && counter > 0) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-voting-secondary-obj'));
      return false;
    }

    return true;
  }

  get allTargets() {
    return [...this.model?.secondary_targets];
  }

  /**
   * Valida i criteri inseriti
   */
  get criteriaValid() {
    let counter = 0;
    for (const criteria of this.model.criteria) {
      if (!criteria.typology || !criteria.title) {
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-criteria-not-valid'));
        return false;
      } else if (this.criteriaService.criteriaIsQuantitative(criteria.typology) && !criteria.measure_unit && !criteria.evaluation) {
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-criteria-not-valid'));
        return false;
      }

      counter++;
    }

    //  se è stato selezionato anche solo un criterio a scelta multipla allora tutti devono essere a scelta multipla
    if (!this.multichoiceCriteriaValid && this.userService?.currentOrganization?.customs?.profile == 'enel') {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-criteria-not-multiplechoice'));
      return false;
    }

    //  eccezione per enel, perché gli altri utenti non dovranno vedere i campi aggiuntivi
    if (['enel', 'alexion'].includes(this.currentOrganization.customs?.profile)) {
      for (const criteria of this.model.criteria) {
        if (this.currentOrganization.customs?.profile === 'enel') {
          if (!criteria.additional.item_id || !criteria.additional.section || !criteria.additional.sub_section) {
            return false;
          }
        }
        if (this.currentOrganization.customs?.profile === 'alexion') {
          if (!criteria.additional.section || !criteria.additional.sub_section) {
            return false;
          }
        }
      }
    }

    //   nella parte della consensus i criteri quantitativi non devono esserci
    if (this.model.analysis_type == 2) {
      for (const criteria of this.model.criteria) {
        if (this.criteriaService.criteriaIsQuantitative(criteria.typology)) {
          this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-quantitative-criteria'));
          return false;
        }
        if (this.criteriaService.criteriaIsMultipleChoice(criteria.typology)) {
          this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-multiplechoice-criteria'));
          return false;
        }
      }
    }

    //  Se è una multicriterio i criteri devono essere almeno 2
    if (this.model.analysis_type == 1 && counter < 2) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-multicriteria-criteria-number'));
      return false;
    }

    //  Nel Voting deve esserci un solo criterio
    if (this.model.analysis_type == 3 && counter > 1) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-voting-criteria'));
      return false;
    }

    return true;
  }

  /**
   * Valida i criteri in caso di scelta multipla
   */
  get multichoiceCriteriaValid() {
    //  controllo che ci sia almeno un criterio a scelta multipla
    const thereIsMultipleChoiceCriteria = this.model.criteria.filter(x => this.criteriaService.criteriaIsMultipleChoice(x.typology));
    if (thereIsMultipleChoiceCriteria && thereIsMultipleChoiceCriteria.length > 0) {

      //  se c'è almeno un criterio a scelta multipla allora controllo che tutti siano a scelta multipla
      const thereIsNotMultipleChoiceCriteria = this.model.criteria.filter(x => !this.criteriaService.criteriaIsMultipleChoice(x.typology));
      if (thereIsNotMultipleChoiceCriteria && thereIsNotMultipleChoiceCriteria.length > 0) {
        return false;
      }

      //  se sono tutti a scelta multipla, controllo che siano stati dati almeno 2 opzioni per ogni criterio a scelta multipla
      for (const criteria of this.model.criteria) {
        if (criteria.options) {
          criteria.options = criteria.options.filter(x => x.title != '');
        }
        if (!criteria.options || criteria.options.length < 2) {
          this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.frameworks.framework.error-criteria-multiplechoice-not-enough-options'));
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Valida i vincoli
   */
  get constraintsValid() {
    for (const constraint of this.model.constraints) {
      if (isNaN(parseInt(constraint.criteria_1_order)) || isNaN(parseInt(constraint.criteria_2_order)) || !constraint.description) {
        return false;
      } else if (constraint.criteria_1_order == constraint.criteria_2_order) {
        return false;
      }

      //  controllo anche se sono stati vincolati alcuni criteri simili tra loro
      let checkConstrait = this.model.constraints.filter(x => x.criteria_1_order == constraint.criteria_1_order);
      checkConstrait = checkConstrait.filter(y => y.criteria_2_order == constraint.criteria_2_order);
      if (checkConstrait.length > 1) {
        return false;
      }
      checkConstrait = this.model.constraints.filter(x => x.criteria_1_order == constraint.criteria_2_order);
      checkConstrait = checkConstrait.filter(y => y.criteria_2_order == constraint.criteria_1_order);
      if (checkConstrait.length > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Valida le risposte prima di aprire il team
   */
  get question_role_satisfied(): boolean {

    //  se non si vogliono inserire i ruoli
    if (this.model.options_role == false) {
      return true;
    }

    //  se si vogliono inserire i ruoli e si è risposto alla richiesta di obbligatorietà di uso dei ruoli
    if (this.model.options_role == true && this.model.options_role_required != null) {
      return true;
    }

    return false;
  }


  /**
   * Valida i ruoli sul framework
   */
  get role_satisfied(): boolean {

    for (const roles of this.model.roles) {
      if (!roles.organization_professional_role_id || !roles.process_role) {
        return false;
      }
    }
    return true;
  }


  public getUnitsService() {
    return this.unitsService;
  }

  public getCriteriaService() {
    return this.criteriaService;
  }

  public getWorkingSectorService() {
    return this.workingSectorService;
  }

  public getProcessRolesService() {
    return this.processRolesService;
  }

  get dynamicAnalysisMethods() {
    if (this.id) {
      return this.analysisMethods.filter((method) => method.enabled === true);
    } else {
      return this.analysisMethods;
    }
  }

  public choiceSetWeights(value) {
    this.weights_is_setted = value;
    if (!value) {
      this.weights_mandatories = null;
    }
  }

  public choiceSetMandatoriesWeights(value) {
    this.weights_mandatories = value;
  }
}
