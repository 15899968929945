<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="!editMode">{{ 'components.decision-table.show-decision-table.add-invite-page' | translate }}</span>
    <span *ngIf="editMode">{{ 'components.decision-table.show-decision-table.invitation-page-details' | translate }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container">
  <form [formGroup]="addInvitationPageForm" (ngSubmit)="onSubmit()" novalidate>
    <mat-card class="w-75 my-5 ml-auto mr-auto">
      <mat-card-content>

        <mat-form-field class="full-width">
          <mat-label>{{ "components.decision-table.new-decision-table.compile-proposal.title" | translate }}</mat-label>
          <input matInput formControlName="title" required [disabled]="loading">
        </mat-form-field>

        <div class="my-2">
          <label class="font-weight-bold mb-1">{{ "generic.description" | translate }} *</label>
          <app-rich-text-editor
            class="full-width"
            formControlName="description"
            required
            [disabled]="loading"
            placeholder="{{ 'components.decision-table.new-decision-table.compile-proposal.placeholders.insert-description' | translate }}">
          </app-rich-text-editor>
        </div>

        <div class="my-2" [formGroup]="addInvitationPageForm.get('customs')">
          <mat-checkbox formControlName="numberPhoneIsMandatory" [disabled]="loading">
            {{ "components.decision-table.show-decision-table.number-phone-is-mandatory" | translate }}
          </mat-checkbox>
        </div>

        <div class="my-2" [formGroup]="addInvitationPageForm.get('customs')">
          <mat-checkbox formControlName="enableThankYouPage" [disabled]="loading">
            {{ "components.decision-table.show-decision-table.enable-thank-you-page" | translate }}
          </mat-checkbox>
        </div>

        <div *ngIf="addInvitationPageForm.get('customs.enableThankYouPage').value" [formGroup]="addInvitationPageForm.get('customs')" class="my-2">
          <label class="font-weight-bold mb-1">{{ "components.decision-table.show-decision-table.thank-you-page" | translate }} *</label>
          <app-rich-text-editor
            class="full-width"
            formControlName="thankYouPage"
            required
            [disabled]="loading"
            placeholder="{{ 'components.decision-table.new-decision-table.compile-proposal.placeholders.insert-thank-you-page' | translate }}">
          </app-rich-text-editor>
        </div>


        <div class="row justify-content-center my-3">
          <a mat-button color="warn" (click)="closeDialogue()" [disabled]="loading" class="mx-2">
            {{ 'generic.buttons.cancel-and-back' | translate }}
          </a>
          <button mat-raised-button color="warn" class="mx-2" [disabled]="loading" (click)="deleteInvitationPage()" *ngIf="data.invitation_page_id">
            {{ 'components.decision-table.show-decision-table.delete-invitation-page' | translate }}
          </button>
          <button mat-raised-button color="primary" class="mx-2" [disabled]="loading || addInvitationPageForm.invalid" type="submit">
            <span *ngIf="editMode">{{ 'generic.update' | translate }}</span>
            <span *ngIf="!editMode">{{ 'generic.send' | translate }}</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
