import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../services/decision.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ToastService} from "../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-modal-add-invitation-page',
  templateUrl: './modal-add-invitation-page.component.html',
  styleUrls: ['./modal-add-invitation-page.component.scss']
})
export class ModalAddInvitationPageComponent implements OnInit {
  addInvitationPageForm: FormGroup;
  loading = false;
  editMode = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAddInvitationPageComponent>,
    private toast: ToastService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { decision_table_id: null, invitation_page_id: null },
    private decisionService: DecisionService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.addInvitationPageForm = this.fb.group({
      id: [''],
      title: ['', Validators.required],
      description: ['', [Validators.required, this.htmlEmptyValidator()]],
      customs: this.fb.group({
        enableThankYouPage: [false],
        thankYouPage: [''],
        numberPhoneIsMandatory: [false]
      })
    });

    this.addInvitationPageForm.get('customs.enableThankYouPage').valueChanges.subscribe(isEnabled => {
      const thankYouPageControl = this.addInvitationPageForm.get('customs.thankYouPage');
      if (isEnabled) {
        thankYouPageControl.setValidators([Validators.required]);
      } else {
        thankYouPageControl.clearValidators();
      }
      thankYouPageControl.updateValueAndValidity();
    });

    if (this.data.invitation_page_id) {
      this.getInvitationPage();
      this.editMode = true;
    }
  }

  private htmlEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isEmpty = control.value === '<p><br></p>' || control.value.trim() === '';
      return isEmpty ? { htmlEmpty: { value: control.value } } : null;
    };
  }

  getInvitationPage(): void {
    this.loading = true;
    this.decisionService.getInvitationPage(this.data.invitation_page_id, (result) => {
      this.loading = false;
      this.setFormValues(result);
    }, () => {
      this.loading = false;
    });
  }

  onSubmit() {
    if (this.addInvitationPageForm.valid) {
      this.loading = true;
      const formValues = this.addInvitationPageForm.value;

      if (this.editMode) {
        this.decisionService.updateInvitationPage(formValues, (result) => {
          this.closeDialogue(result);
          this.toast.success(
            this.translateService.instant('generic.success'),
            this.translateService.instant('components.decision-table.show-decision-table.invitation-page-updated-successfully'),
          );
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      } else {
        this.decisionService.addNewInvitationPage(this.data.decision_table_id, formValues, (result) => {
          this.closeDialogue(result);
          this.toast.success(
            this.translateService.instant('generic.success'),
            this.translateService.instant('components.decision-table.show-decision-table.invitation-page-created-successfully'),
          );
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    }
  }

  deleteInvitationPage() {
    this.loading = true;
    this.decisionService.deleteInvitationPage(this.data.invitation_page_id, (result) => {
      this.loading = false;
      this.closeDialogue({isDeleted: true});
      this.toast.success(
        this.translateService.instant('generic.success'),
        this.translateService.instant('components.decision-table.show-decision-table.invitation-page-deleted-successfully'),
      );
    }, () => {
      this.loading = false;
    });
  }

  closeDialogue(result: any = null) {
    this.dialogRef.close(result);
  }

  private setFormValues(data: any, formGroup: FormGroup = this.addInvitationPageForm) {
    Object.keys(data).forEach(key => {
      if (key === 'customs' && data[key]) {
        const customs = data[key];
        const customsGroup = formGroup.get('customs') as FormGroup;
        Object.keys(customs).forEach(customKey => {
          if (customsGroup.contains(customKey)) {
            customsGroup.get(customKey).patchValue(customs[customKey] || '');
          }
        });
      } else if (formGroup.contains(key)) {
        formGroup.get(key).patchValue(data[key]);
      }
    });
  }
}
